<script>
import i18n from '@/i18n';
export default {
  name: "OpinionPrivacySelect",
  props: {
    privacy: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showDropdown: false
    }
  },
  computed: {
    selectIconClass() {
      let icon = '';

      if (this.privacy === 'PRIVATE') {
        icon = 'private';
      } else if (this.privacy === 'PUBLIC') {
        icon = 'public';
      }

      return icon;
    },
    selectLabel() {
      let label = '';

      if (this.privacy === 'PRIVATE') {
        label = i18n.global.t('opinion.private');
      } else if (this.privacy === 'PUBLIC') {
        label = i18n.global.t('opinion.public');
      }

      return label;
    }
  },
  methods: {
    togglePrivacyDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    setPrivacy(privacyVal) {
      this.$emit('on-change', privacyVal)
    }
  }
}
</script>

<template>
  <div class="privacy-select-section">
    <div class="privacy-select" @click="togglePrivacyDropdown">
      <i class="privacy-select__icon"
         :class="selectIconClass"
      ></i>
      <span class="privacy-select__label">
        {{selectLabel}}
      </span>
      <i class="privacy-select__control"></i>
    </div>
    <div v-if="showDropdown"
         class="privacy-select-dropdown"
         @click="togglePrivacyDropdown"
    >
      <div class="privacy-select-dropdown-item privacy-select-dropdown-item--private"
           @click="setPrivacy('PRIVATE')"
      >
        <i class="privacy-select-dropdown-item__icon"></i>
        <span class="privacy-select-dropdown-item__text">
          {{$t('opinion.private')}}
        </span>
      </div>
      <div class="privacy-select-dropdown-item privacy-select-dropdown-item--public"
           @click="setPrivacy('PUBLIC')"
      >
        <i class="privacy-select-dropdown-item__icon"></i>
        <span class="privacy-select-dropdown-item__text">
          {{$t('opinion.public')}}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.privacy-select-section {
  position: relative;

  .privacy-select {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__icon {
      display: block;
      width: 10px;
      height: 14px;
      margin-right: 5px;
      background-size: 100% !important;
      background-position: center !important;
      &.public {
        background: url("~@/assets/img/opinion/PUBLIC.svg") no-repeat;
      }
      &.private {
        background: url("~@/assets/img/opinion/PRIVATE.svg") no-repeat;
      }
    }
    &__label {
      display: block;
      margin-right: 5px;
      font-size: 12px;
      color: #7835a6;
    }
    &__control {
      display: block;
      width: 7px;
      height: 7px;
      background: url("~@/assets/img/opinion/purple_arrow_3.svg") no-repeat center;
      background-size: 100%;
    }
  }

  .privacy-select-dropdown {
    position: absolute;
    top: -15px;
    left: -11px;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: space-around;
    height: 44px;
    padding: 1px 14px;
    background-color: #FFFFFF;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border: solid 1px #f2f2f2;

    min-width: 84px;
    z-index: 10;
    box-shadow: 0 2px 12px 0 rgba(215,215,215,0.5);

    .privacy-select-dropdown-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      &__icon {
        display: block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        background-size: 100% !important;
      }
      &__text {
        font-size: 12px;
        color: #7835a6;
      }
      &--private {
        .privacy-select-dropdown-item__icon {
          height: 12px;
          width: 8px;
          margin-left: 1px;
          background: url("~@/assets/img/opinion/PRIVATE.svg") no-repeat;
        }
      }
      &--public {
        .privacy-select-dropdown-item__icon {
          background: url("~@/assets/img/opinion/PUBLIC.svg") no-repeat;
        }
      }
    }
  }

}

</style>