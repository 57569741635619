<script>
import {mapActions, mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";
import Dialog from "primevue/dialog";
import BaseAvatar from "@/components/BaseAvatar/BaseAvatar.vue";

export default {
  name: "ProposersListModal",
  components: {
    Dialog,
    BaseAvatar
  },
  props: {
    proposalTitle: {
      type: String
    },
    proposersList: {
      type: Array
    }
  },
  computed: {
    ...mapState(proposalStore, ['proposersModalState']),
    modalState: {
      get() {
        return this.proposersModalState;
      },
      set() {
        this.toggleProposersModal();
      },
    },
  },
  methods: {
    ...mapActions(proposalStore, ['toggleProposersModal']),
  },
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="proposers-modal"
          modal
          :header="$t('proposerListModal.title')"
  >
    <span class="proposers-info">
      {{$t('proposerListModal.info')}} {{proposalTitle}}
    </span>
    <div v-for="item in proposersList"
         :key="item.id"
         class="proposer-item">
      <base-avatar :size="'M'" :img-hash="item.imgHash" class="proposer-item__avatar"/>
      <div class="proposer-item__info">
        <router-link v-if="item.type === 'ORGANIZATION'"
                     :to="{name: 'OrganizationView', params: {id: item.id}}"
                     target="_blank"
                     class="proposer-item__name"
        >{{item.name}}</router-link>
        <router-link v-else
                     :to="{name: 'UserView', params: {id: item.id}}"
                     target="_blank"
                     class="proposer-item__name"
        >{{item.name}}</router-link>
      </div>
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="toggleProposersModal()">
        {{$t('common.buttons.close')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.proposers-modal {
  min-width: 660px;

  .proposers-info {
    display: flex;
    margin: 5px 0 15px;
    font-size: 14px;
  }

  .proposer-item {
    display: flex;
    align-items: center;
    height: 68px;

    &__avatar {
      margin-right: 6px;
    }
    &__info {
      display: flex;
      align-items: center;
      height: 68px;
      width: 100%;
      padding-left: 3px;
      border-bottom: 1px solid #e9e9e9;
    }
    &__name {
      font-size: 14px;
      font-weight: 600;
      color: #7835a6;
      cursor: pointer;
      text-decoration: none;
    }

    &:last-child {
      .proposer-item__info {
        border-bottom: transparent;
      }
    }
  }
}
</style>