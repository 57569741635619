import { defineStore } from 'pinia';
import axios from 'axios';
export const documentStore = defineStore({
    id: 'documentStore',
    state: () => ({

    }),
    actions: {
        createAttachmentDocument(file, type, title) {
            let bodyFormData = new FormData();

            bodyFormData.append('qqfile',  new Blob(file), file[0].name);
            bodyFormData.append('title',  title);
            bodyFormData.append('type',  type);
            return axios.post('/api/cms/attachment', bodyFormData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
        },
        updateAttachmentDocument(contentId, attachmentId, file) {
            let bodyFormData = new FormData();

            bodyFormData.append('qqfile',  new Blob(file), file[0].name);
            return axios.put(`/api/cms/${contentId}/attachment/${attachmentId}`, bodyFormData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
        },
        createTextDocument(data) {
            return axios.post(`/api/cms/text`, data);
        },
        updateTextDocument(contentId, data) {
            return axios.put(`/api/cms/${contentId}/text`, data);
        },
        downloadAttachment(attachmentsHash) {
            return axios.get(`/api/attachment/${attachmentsHash}/download`, { responseType: 'blob' });
        },
        getDocumentContent(documentId) {
            return axios.get(`/api/cms/${documentId}`);
        },
        getTextDocumentContent(documentId) {
            return axios.get(`/api/cms/${documentId}/text`);
        }
    }
})