import {defineStore} from 'pinia';

export const opinionStore = defineStore({
    id: 'opinionStore',
    state: () => ({
        addArgumentsModalState: false,
        opinionArguments: [],
        currentOpinion: null
    }),
    actions: {
        getOpinion() {
            this.currentOpinion = {
                "id": 283249,
                "version": 1,
                "opinionNumber": "oo404",
                "opinionLike": "VERY_GOOD",
                "proposalIds": [
                    2445747
                ],
                "proposalIssueNumber": "pp189181",
                "proposalRelation": {},
                "importanceType": "NOT_RELEVANT",
                "changeType": "NONE",
                "status": "CURRENT",
                "creator": {
                    "id": 12935,
                    "name": "Ulrika Heindorff",
                    "imgHash": "2958351f8e976a4040564f9638e987341e865efd",
                    "type": "USER"
                },
                "author": {
                    "id": 12935,
                    "name": "Ulrika Heindorff",
                    "imgHash": "2958351f8e976a4040564f9638e987341e865efd",
                    "type": "USER"
                },
                "versions": [
                    {
                        "id": 283249,
                        "creator": null,
                        "privateSinceDateTime": null,
                        "version": 1,
                        "opinionLike": "VERY_GOOD",
                        "changeType": "NONE",
                        "status": "CURRENT",
                        "createdDateTime": "2024-10-22T22:42:50.475+02:00[Europe/Stockholm]",
                        "updatedDateTime": "2024-10-22T22:55:49.207+02:00[Europe/Stockholm]"
                    }
                ],
                "createdDateTime": "2024-10-22T22:42:50.475+02:00[Europe/Stockholm]",
                "updatedDateTime": "2024-10-22T22:55:49.207+02:00[Europe/Stockholm]"
            }
        },
        toggleAddArgumentsModalState() {
            this.addArgumentsModalState = !this.addArgumentsModalState;
        },
        addOpinionArgument(argData) {
            this.opinionArguments.push(argData);
        }
    }
});