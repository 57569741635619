<script>
import {mapActions, mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";
import {argumentStore} from "@/store/argumentStore";
import {documentStore} from "@/store/documentStore";

export default {
  name: "ProposalArgumentPlayerPlaylist",
  data() {
    return {
      nextArgView: true,
      nextArguments: [],
    }
  },
  computed: {
    ...mapState(proposalStore, [
      'proposalArguments'
    ]),
    ...mapState(argumentStore, [
      'activeArgument',
      'activeArgumentIndex',
      'isLastArgument'
    ]),
    nextArgumentItem() {
      return this.isLastArgument
          ? this.nextArguments[this.activeArgumentIndex]
          : this.nextArguments[this.activeArgumentIndex + 1];
    }
  },
  methods: {
    ...mapActions(documentStore, [
      'getTextDocumentContent'
    ]),
    ...mapActions(argumentStore, [
      'setActiveArgument',
    ]),
    showAllArguments() {
      this.nextArgView = false;
    },
    selectArgument(arg, index) {
      this.setActiveArgument(arg, index);
    },
  },
  mounted() {
    this.nextArguments = this.proposalArguments;
    this.nextArguments.forEach((arg)=> {
      if(arg.argumentDetailed.type === "HTML_TEXT") {
        this.getTextDocumentContent(arg.argumentDetailed.contentId).then((documentText)=> {
          arg.argumentText = documentText.data;
        });
      }
    })
  }
}
</script>

<template>
<div class="argument-player-playlist">
  <template v-if="nextArgView">
    <div class="next-arg-view">
      <div class="next-argument-timer">
        <div class="next-argument-timer__label">Watch next argument</div>
        <div class="next-argument-timer__btn" @click="showAllArguments">Cancel</div>
      </div>
      <div v-if="nextArgumentItem" class="next-argument-item" @click="selectArgument(nextArgumentItem, activeArgumentIndex + 1)">
        <div class="next-argument-item__img-wrap">
          <img v-if="nextArgumentItem.argumentDetailed.type === 'VIDEO'"
               :src="`/api/video/${nextArgumentItem.argumentDetailed.attachments[1].hash}/preview`"
               class="next-argument-item__thumbnail"
          >
          <div v-else class="next-argument-item__text-thumbnail">{{nextArgumentItem}}</div>
        </div>

        <span class="next-argument-item__title">{{nextArgumentItem.title}}</span>
      </div>
    </div>
  </template>
  <template v-else>
    <span class="argument-player-playlist__title">See more arguments</span>
    <div class="arguments-list">
      <div v-for="(arg, index) in proposalArguments" class="argument-list-item"
           :key="arg.id"
           @click="selectArgument(arg, index)"
      >
        <template v-if="arg.argumentDetailed.type === 'VIDEO'">
          <img :src="`/api/video/${arg.argumentDetailed.attachments[1].hash}/preview`"
               class="argument-list-item__thumbnail"
          >
          <span class="argument-list-item__title">{{arg.title}}</span>
        </template>
        <template v-else-if="arg.argumentDetailed.type === 'HTML_TEXT'">
          <div class="argument-list-item__text-thumbnail" v-html="arg.argumentText"></div>
          <span class="argument-list-item__title">{{arg.title}}</span>
        </template>
      </div>
      <div class="addition-overlay"></div>
    </div>
  </template>
</div>
</template>

<style lang="scss" scoped>
.argument-player-playlist {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  padding: 7px;
  background-image: linear-gradient(to bottom, rgba(53, 13, 80, .7), #350d50 100%);
  &__title {
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
    text-align: center;
  }

  .next-arg-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7px 0;
    box-sizing: border-box;
    .next-argument-timer {
      display: flex;
      margin: 15px 0 25px;
      align-items: center;
      &__label {
        margin-right: 18px;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        line-height: 1.31;
        letter-spacing: -.01px;
      }
      &__btn {
        padding: 3px 8px;
        font-size: 12px;
        font-weight: 600;
        color: #fff;
        line-height: 1.5;
        letter-spacing: -.01px;
        cursor: pointer;
      }
    }

    .next-argument-item {
      width: 247px;
      margin: 0 auto;
      &__img-wrap {
        width: 247px;
        height: 139px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        &:after {
          content: '';
          display: block;
          position: absolute;
          height: 45px;
          width: 45px;
          background: url("~@/assets/img/proposal/video-play-btn-75.svg") center no-repeat;
          background-size: 100%;
        }
      }
      &__thumbnail {
        width: 247px;
        height: 139px;
        margin-bottom: 5px;
      }
      &__text-thumbnail {
        width: 199px;
        height: 112px;
        padding: 3px 10px;
        overflow: hidden;
        background-image: linear-gradient(119deg, #bc5bff, #8e4eba 99%);
        border: .5px solid #fff;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: -.01px;
        color: #fff !important;
      }
      &__title {
        display: inline-block;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: -.01px;
        color: #fff;
      }
    }
  }

  .addition-overlay {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity .1s linear;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 40;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(53, 13, 80, .4), #350d50 100%);
  }

  .arguments-list {
    display: flex;
    .argument-list-item {
      margin: 3px 5px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      &__thumbnail {
        width: 199px;
        height: 112px;
        margin-bottom: 5px;
      }
      &__text-thumbnail {
        width: 199px;
        height: 112px;
        padding: 3px 10px;
        margin-bottom: 5px;
        overflow: hidden;
        background-image: linear-gradient(119deg, #bc5bff, #8e4eba 99%);
        border: .5px solid #fff;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: -.01px;
        color: #fff !important;
      }
      &__title {
        display: inline-block;
        width: 199px;
        overflow: hidden;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: -.01px;
        color: #fff;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:hover {
        position: relative;
        z-index: 50;
        top: -5px;
        padding: 5px 5px 0;
        margin: 3px 0 -2px;
        background: #fff;
        border-radius: 4px;
        .argument-list-item__title {
          color: #4f4f4f
        }

        &~.addition-overlay {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

}

</style>