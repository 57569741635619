import { defineStore } from 'pinia';
import axios from 'axios';
export const notificationStore = defineStore({
    id: 'notificationStore',
    state: () => ({
        newsEventsCount: 0,
        criticalEventsCount: 0,
    }),
    actions: {
        getUnreadNewsCountAction() {
            axios.get('/api/news/unread/count')
                .then((res) => {
                    this.newsEventsCount = res.data;
                })
                .catch(error => console.log(error));
        },
        getCriticalEventCountAction() {
            axios.get('/api/event/unread/count')
                .then((res) => {
                    this.criticalEventsCount = res.data.length;
                })
                .catch(error => console.log(error));
        },
        cleanNotificationStore() {
            this.newsEventsCount = 0;
            this.criticalEventsCount = 0;
        }
    }
})