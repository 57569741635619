<script>
import { mapState, mapActions } from 'pinia'
import { baseLoaderStore} from "@/store/baseLoaderStore";
import BaseIframe from "@/components/BaseIframe.vue";
export default {
  name: "ManagerKnowledgeAreaView",
  components: {
    BaseIframe
  },
  computed: {
    ...mapState(baseLoaderStore, ['loaderState']),
    frameUrl() {
      let url = this.$route.fullPath;

      if (!url.includes('_f=true')) {
        if (!url.match(/\?./)) {
          url += '?_f=true';
        } else {
          url += '&_f=true';
        }
      }
      return url;
    }
  },
  methods: {
    ...mapActions(baseLoaderStore, ['hideBaseLoader']),
  },
  mounted(){
    this.hideBaseLoader();
  },
}
</script>

<template>
  <Base-iframe
      :src="frameUrl"
      :disable-loader="true"
  />
</template>