<script>
export default {
  name: "ProposalActionArgumentRatedControls",
  props: {
    disabledState: {
      type: Boolean
    }
  }
}
</script>

<template>
  <div class="arguments-rated-controls">
    <span class="arguments-rated-controls__title">
      {{$t('arguments.argumentsYouRated')}}
    </span>
    <div class="arguments-rated-controls__items">
      <div class="arguments-rated-item arguments-rated-item--agreed"
           :class="{'arguments-rated-item--disabled': disabledState}"
      >
        <span class="arguments-rated-item__caption">
          {{$t('arguments.agree')}}
        </span>
        <span class="arguments-rated-item__count">
          0
        </span>
      </div>
      <div class="arguments-rated-controls__separator"></div>
      <div class="arguments-rated-item arguments-rated-item--disagreed"
           :class="{'arguments-rated-item--disabled': disabledState}"
      >
        <span class="arguments-rated-item__caption">
          {{$t('arguments.disagree')}}
        </span>
        <span class="arguments-rated-item__count">
          0
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.arguments-rated-controls {
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #4f4f4f;
  }
  &__items {
    display: flex;
    flex-direction: row;
  }
  &__separator {
    width: 1px;
    height: 18px;
    margin: 0 10px;
    background: #f2f2f2;
  }

  .arguments-rated-item {
    display: flex;
    align-items: center;
    padding-top: 1px;
    padding-bottom: 2px;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    &__caption {
      margin-right: 5px;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: -0.01px;
      white-space: nowrap;
    }
    &__count {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 17px;
      height: 17px;
      border-radius: 9px;
      font-size: 12px;
      color: #FFFFFF;
      text-align: center;
    }

    &--agreed {
      color: #01c77e;
      &:hover {
        border-bottom: 1px solid #01c77e;
      }
      .arguments-rated-item__count {
        background: #01c77e;
      }
    }
    &--disagreed {
      color: #e64407;
      &:hover {
        border-bottom: 1px solid #e64407;
      }
      .arguments-rated-item__count {
        background: #e64407;
      }
    }
    &--disabled {
      color: #d1d1d1;
      cursor: default;

      .arguments-rated-item__count {
        background: #d1d1d1;
      }
      &:hover {
        border-bottom: none;
      }
    }
  }
}
</style>