<script>
import BaseAvatar from "@/components/BaseAvatar/BaseAvatar.vue";
export default {
  name: "ProposerItem",
  props: {
    itemData: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseAvatar
  },
  computed: {
    isOrg() {
      return this.itemData.type === "ORGANIZATION";
    },
    haveOrg() {
      return this.itemData.userId && this.itemData.orgId;
    },
    avatarHash() {
      return this.itemData.imgHash;
    },
  }
}
</script>

<template>
  <div class="proposer-item">
    <BaseAvatar
        :img-hash='avatarHash'
        :size="'32'"
        class="proposer-item__avatar"
    />
    <router-link v-if="haveOrg" :to="{name: 'OrganizationView', params: {id: itemData.orgId}}" target="_blank">
      <BaseAvatar :img-hash='itemData.imgHash'
                  :size="'SM'"
                  v-tooltip.top="itemData.imgHash"
                  class="proposer-item__org"
      />
    </router-link>
    <router-link v-if="isOrg"
                 :to="{name: 'OrganizationView', params: {id: itemData.id}}"
                 target="_blank"
                 class="proposer-item__name"
    >{{itemData.name}}</router-link>
    <router-link v-else
                 :to="{name: 'UserView', params: {id: itemData.id}}"
                 target="_blank"
                 class="proposer-item__name"
    >{{itemData.name}}</router-link>
  </div>
</template>

<style lang="scss" scoped>
.proposer-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
  position: relative;

  &__org {
    position: relative;
    top: 5px;
    left: -7px;
  }

  &__name {
    margin-left: 7px;
    font-size: 12px;
    font-weight: 600;
    color: #7835a6;
    text-decoration: none;
  }

  &:last-child {
    margin-right: 0;
  }
}
</style>