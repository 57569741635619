<script>
import BaseIframe from "@/components/BaseIframe.vue";
export default {
  name: "PrivacyPolicyView",
  components: {
    BaseIframe
  },
}
</script>

<template>
  <Base-iframe
      :src="'/privacypolicy?_f=true'"
      :use-sandbox="false"
  />
</template>

