<script>

export default {
  name: "ProposalOpinionsOfOthers",
  data() {
    return {
      searchString: ''
    }
  }
}
</script>

<template>
<div class="opinions-of-others">
  <div class="opinions-of-others__header">
    <div class="section-title">
      <i class="section-title__icon"></i>
      <span class="section-title__text">Opinions of others</span>
    </div>
    <div class="opinions-search-wrap">
      <input type="text" v-model="searchString" class="opinions-search-field">
    </div>
  </div>
  <div class="opinions-of-others__content">

  </div>
</div>
</template>

<style lang="scss" scoped>
.opinions-of-others {
  display: flex;
  width: 620px;
  height: 430px;
  padding: 14px 20px 16px 15px;
  background: #fff;

  &__header {
    .section-title {
      display: flex;
      &__icon {
        display: block;
        width: 30px;
        height: 21px;
        margin-right: 10px;
        background: url("~@/assets/img/proposal/opinions_icon.svg"), no-repeat, center;
      }
      &__text {
        font-size: 16px;
        font-weight: 600;
        color: #4f4f4f
      }
    }
    .opinions-search-wrap {
      .opinions-search-control {
        &__field {
          height: 22px;
          line-height: 22px;
          width: 255px;
          padding-right: 25px;
        }
        &__icon {
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  &__content {

  }
}
</style>