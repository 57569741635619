import { defineStore } from 'pinia';

export const mainSearchStore = defineStore({
    id: 'mainSearchStore',
    state: () => ({
        searchResultInfoModalState: false,
        searchResultInfoModalCallback: null
    }),
    actions: {
        openSearchResultInfoModal(callback) {
            this.searchResultInfoModalState = true;
            if (callback) {
                this.searchResultInfoModalCallback = callback;
            }
        },
        closeSearchResultInfoModal() {
            this.searchResultInfoModalState = false;
            if (this.searchResultInfoModalCallback) {
                this.searchResultInfoModalCallback();
            }
        },

    }
})