<script>
import ProgressBar from "primevue/progressbar";
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import {proposalStore} from "@/store/proposalStore";
import {argumentStore} from "@/store/argumentStore";
import i18n from "@/i18n";
export default {
  name: "ProposalArgumentPlayerControls",
  components: {
    ProgressBar
  },
  data() {
    return {
      showState: false,
      progressbarVal: 0,
      resetReactionMenuState: false
    }
  },
  computed: {
    ...mapState(authStore, [
      'userInfo',
      'userLoggedIn',
      'userIsManager'
    ]),
    ...mapState(proposalStore, [
      'proposalArguments'
    ]),
    ...mapState(argumentStore, [
      'activeArgument',
      'activeArgumentIndex',
      'isFirstArgument',
      'isLastArgument',
      'argRatings',
      'argRatingCount',
      'currentArgumentRating'
    ]),
    disabledReactionControls() {
      return !this.userLoggedIn || this.userIsManager || this.isOwnerOfArgument;
    },
    reactionControlsInfoText() {
      let infoText = '';

      if (!this.userLoggedIn) {
        infoText = i18n.global.t('argument.rate.userNeedToBeLogged');
      }

      if (this.userIsManager) {
        infoText = i18n.global.t('argument.rate.managerCanNotRateArgument');
      }

      if (this.isOwnerOfArgument) {
        infoText = i18n.global.t('argument.rate.notPossibleRate');
      }

      return infoText;
    },
    isOwnerOfArgument() {
      let state = false;

      if (this.userInfo && this.activeArgument) {
        state = this.userInfo.id === this.activeArgument.creator.id;
      }

      return state;
    },
    hasReaction() {
      return this.currentReactionAgree || this.currentReactionDisagree;
    },
    currentReactionAgree() {
      return this.currentArgumentRating ? this.currentArgumentRating.type === 'AGREE' : false;
    },
    currentReactionDisagree() {
      return this.currentArgumentRating ? this.currentArgumentRating.type === 'DISAGREE' : false;
    },
    argHasReactions() {
      return this.argRatingCount.agree > 0 || this.argRatingCount.disagree;
    },
    agreePercent() {
      return (this.argRatingCount.agree * 100) / (this.argRatingCount.agree + this.argRatingCount.disagree);
    }
  },
  methods: {
    ...mapActions(argumentStore, [
      'getArgumentAllRatings',
      'getCurrentArgumentRatings',
      'getArgumentCount',
      'setActiveArgument',
      'prevArgumentAction',
      'nextArgumentAction',
      'rateArgumentAction',
    ]),
    rateArgument(ratingType) {
      this.resetReactionMenuState = false;

      if (ratingType === 'AGREE' && this.currentReactionAgree || ratingType === 'DISAGREE' && this.currentReactionDisagree) {
        return;
      }

      this.rateArgumentAction(this.activeArgument.id, ratingType).then(()=> {
        this.getArgumentCount(this.activeArgument.id);
      })
    },

    toggleResetReactionMenu() {
      this.resetReactionMenuState = !this.resetReactionMenuState;
    }
  },
  beforeMount() {
    if (this.userLoggedIn) {
      this.getArgumentAllRatings(this.activeArgument.id).then(()=> {
        if (this.argRatings.length > 0) {
          this.getCurrentArgumentRatings(this.activeArgument.id).then(()=> {
            this.showState = true;
          })
        } else {
          this.showState = true;
        }
      });
    } else {
      this.showState = true;
    }

    this.getArgumentCount(this.activeArgument.id);
  }
}
</script>

<template>
<div class="argument-player-controls">
  <span class="argument-player-controls__title">
    {{$t('argument.rate.doYouAgree')}}
  </span>
  <div class="argument-player-controls__buttons" v-if="showState">
    <div class="player-nav-control player-nav-control--prev"
         :class="{'player-nav-control--disabled': isFirstArgument}"
         v-tooltip.top="!isFirstArgument ? $t('argument.rate.seePreviousArgument') : ''"
         @click="prevArgumentAction()"
    >
      <font-awesome-icon :icon="['fas', 'step-backward']"/>
    </div>
    <div v-if="!hasReaction || currentReactionAgree"
         class="player-reaction-control player-reaction-control--agree"
         :class="{'disabled': disabledReactionControls, 'active': currentReactionAgree}"
         v-tooltip.top="{ value: reactionControlsInfoText, class: 'rate-arg-tooltip' }"
         @click="rateArgument('AGREE')"
    >
      <font-awesome-icon :icon="['fas', 'thumbs-up']" class="player-reaction-control__icon"/>
      <span class="player-reaction-control__caption">
        <template v-if="currentReactionAgree">
            {{$t('argument.rate.agreed')}}
        </template>
        <template v-else>
            {{$t('argument.rate.agree')}}
        </template>
      </span>
    </div>
    <div v-if="hasReaction" class="player-reaction-control player-reaction-control--change-mind"
        :class="{'active': resetReactionMenuState}"
    >
      <div @click="toggleResetReactionMenu()">
        <font-awesome-icon :icon="['fas', 'undo']" class="player-reaction-control__icon"/>
        <span class="player-reaction-control__caption">
          {{$t('argument.rate.changeMyMind')}}
        </span>
      </div>
      <div v-if="resetReactionMenuState"
           class="player-reaction-control__dropdown"
      >
        <div class="dropdown-item" @click="rateArgument('NONE')">
          {{$t('argument.rate.removeMyRating')}}
        </div>
        <template v-if="currentReactionDisagree">
          <div class="dropdown-item" @click="rateArgument('AGREE')">
            {{$t('argument.rate.changeToAgree')}}
          </div>
        </template>
        <template v-if="currentReactionAgree">
          <div class="dropdown-item" @click="rateArgument('DISAGREE')">
            {{$t('argument.rate.changeToDisagree')}}
          </div>
        </template>
      </div>
      <div v-if="resetReactionMenuState"
           @click="toggleResetReactionMenu()"
           class="player-reaction-control__dropdown-overlay"></div>
    </div>
    <div v-if="!hasReaction || currentReactionDisagree"
         class="player-reaction-control player-reaction-control--disagree"
         :class="{'disabled': disabledReactionControls, 'active': currentReactionDisagree}"
         v-tooltip.top="{ value: reactionControlsInfoText, class: 'rate-arg-tooltip' }"
         @click="rateArgument('DISAGREE')"
    >
      <font-awesome-icon :icon="['fas', 'thumbs-down']" class="player-reaction-control__icon"/>
      <span class="player-reaction-control__caption">
        <tempalte v-if="currentReactionDisagree">
             {{$t('argument.rate.disagreed')}}
        </tempalte>
        <tempalte v-else>
          {{$t('argument.rate.disagree')}}
        </tempalte>
      </span>
    </div>
    <div v-if="hasReaction" class="player-reaction-control player-reaction-control--next"
         :class="{'disabled': isLastArgument}"
    >
      <span class="player-reaction-control__caption">
        {{$t('argument.rate.seeNextArgument')}}
      </span>
      <font-awesome-icon :icon="['fas', 'arrow-right']" class="player-reaction-control__icon"/>
    </div>
    <div class="player-nav-control player-nav-control--next"
         v-tooltip.top="!isLastArgument ? 'See next argument' : ''"
         :class="{'player-nav-control--disabled': isLastArgument}"
         @click="nextArgumentAction()"
    >
      <font-awesome-icon :icon="['fas', 'step-forward']"/>
    </div>
  </div>
  <div v-if="showState" class="argument-player-controls__ratio-bar">
    <progress-bar :value="agreePercent"
                  :show-value="false"
                  :class="{'disabled': disabledReactionControls || !argHasReactions}"
    />
    <span v-if="!argHasReactions" class="progressbar-info">
     {{$t('argument.rate.nobodyPublicly')}}
    </span>
    <div v-else class="rates-info-block">
      <div class="rates-info-item rates-info-item--agree">
        <font-awesome-icon :icon="['fas', 'thumbs-up']" class="rates-info-item__icon"/>
        <span class="rates-info-item__label">
          {{argRatingCount.agree}} {{$t('argument.rate.agreed')}}
        </span>
      </div>
      <div class="rates-info-item rates-info-item--disagree">
        <font-awesome-icon :icon="['fas', 'thumbs-down']" class="rates-info-item__icon"/>
        <span class="rates-info-item__label">
          {{argRatingCount.disagree}} {{$t('argument.rate.disagreed')}}
        </span>
      </div>
    </div>
  </div>
</div>
</template>

<style lang="scss">
.argument-player-controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 30px;

  &__title {
     margin-bottom: 10px;
     font-size: 16px;
     font-weight: 600;
     text-align: center;
     line-height: 1.5;
     letter-spacing: -.01px;
     color: #4f4f4f;
   }

  &__buttons {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 20px;

    .player-nav-control {
      display: flex;
      width: 33px;
      height: 38px;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: 2px solid #f2f2f2;
      box-sizing: border-box;
      cursor: pointer;
      color: #4f4f4f;

      &:hover {
        box-shadow: 0 0 6px 0 rgba(79, 79, 79, .27);
        border: .5px solid #f2f2f2;
        background-color: #f1e8f8;
      }

      &--prev {

      }
      &--next {

      }
      &--disabled {
        box-shadow: none;
        border: 2px solid #f2f2f2;
        background: #FFF;
        cursor: default;
        color: #d1d1d1;
        &:hover {
          box-shadow: none;
          border: 2px solid #f2f2f2;
          background: #FFF;
        }
      }
    }

    .player-reaction-control {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 38px;
      max-width: 231px;
      position: relative;
      border-radius: 5px;
      box-shadow: 0 0 6px 0 rgba(79, 79, 79, .27);
      border: .5px solid #f2f2f2;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        background-color: #e3e3e3;
        border: .5px solid #e3e3e3;
      }

      &__icon {
        margin-right: 6px;
      }

      &__caption {
        font-size: 14px;
        font-weight: 600;
      }

      &__dropdown {
        position: absolute;
        z-index: 1;
        top: 40px;
        width: max-content;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: 0 0 6px 0 rgba(79, 79, 79, 0.27);
        background-color: #fff;

        .dropdown-item {
          padding: 15px 20px;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          color: #4f4f4f;
          &:hover {
            background: #e3e3e3;
          }
        }
      }

      &__dropdown-overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        height: 100%;
        width: 100%;
      }

      &--agree {
        color: #01c77e;
        &.active {
          cursor: default;
          background: #dffff3;
          color: #4f4f4f;
          border: solid 0.5px #f2f2f2;
          box-shadow: none;
          &:hover {
            border: solid 0.5px #f2f2f2;
          }
        }
      }

      &--disagree {
        color: #e64407;
        &.active {
          cursor: default;
          background: #ffcdba;
          color: #4f4f4f;
          border: solid 0.5px #f2f2f2;
          box-shadow: none;
          &:hover {
            border: solid 0.5px #f2f2f2;
          }
        }
      }

      &--change-mind {
        background: #FFFFFF;
        color: #7b7b7b;
        border: solid 1px #d1d1d1;

        &.active,
        &:hover {
          border: solid 1px #d1d1d1;
          background: #E3E3E3;
        }
      }

      &--next {
        max-width: 165px;
        color: #FFFFFF;
        background-image: linear-gradient(102deg, #881ed1, #c36aeb 100%);
        border: none;

        .player-reaction-control__caption {
          margin-right: 5px;
        }

        &:hover {
          box-shadow: 0 1px 8px 0 rgba(120, 53, 166, 0.75);
        }

        &.disabled {
          color: #fff;
          background-image: linear-gradient(102deg, #9a9a9a, #c1c1c1 100%);

          &:hover {
            box-shadow: none;
          }
        }
      }

      &.disabled {
        border: 1px solid #d1d1d1;
        box-shadow: none;
        color: #d1d1d1 !important;
        background: #fff;
        cursor: default;
      }
    }
  }

  &__ratio-bar {
    .p-progressbar {
      height: 7px;
      margin-bottom: 10px;
      border-radius: 4px;
      background-color: #e64407;
      .p-progressbar-value {
        background-color: #01c77e;
      }

      &.disabled {
        background-color: #ccc;
        .p-progressbar-value {
          background-color: #ccc;
        }

      }
    }
    .progressbar-info {
      display: flex;
      width: 100%;
      justify-content: center;
      min-height: 21px;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: -.01px;
      text-align: center;
      color: #3c3c3c
    }

    .rates-info-block {
      display: flex;
      justify-content: space-between;
      .rates-info-item {
        display: flex;
        &__icon {
          margin-right: 5px;
          font-size: 12px;
        }
        &__label {
          font-size: 12px;
          font-weight: 600;
        }

        &--agree {
          color: #01c77e;
        }
        &--disagree {
          color: #e64407;
        }
      }
    }
  }
}
.rate-arg-tooltip {
  min-width: fit-content;
}
</style>