<script>
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import {forumSelectStore} from "@/store/forumSelectStore";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import i18n from "@/i18n";
import Dialog from "primevue/dialog";
import Select from 'primevue/select';
import Popover from 'primevue/popover';
import ForumsSelectTree from "@/components/ForumSelect/ForumsSelectTree.vue";

export default {
  name: "CreateOrgModal",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Dialog,
    Select,
    Popover,
    ForumsSelectTree
  },
  data() {
    return {
      orgName: "",
      orgNameCharCountState: false,
      orgType: null,
      applicantPhone: "",
      applicantPhoneCharCountState: false,
      otherInfo: "",
      otherInfoCountState: false,
      selectedForum: '',
      disabled: true,
      orgTypes: [
        {name: i18n.global.t('createOrg.orgTypes.party'), code: 'PARTY', disabled: true},
        {name: i18n.global.t('createOrg.orgTypes.youthOrg'), code: 'YOUTH_ORGANIZATION'},
        {name: i18n.global.t('createOrg.orgTypes.nonGovernmentalOrg'), code: 'NON_GOVERNMENT_ORGANIZATION'},
        {name: i18n.global.t('createOrg.orgTypes.other'), code: 'OTHER'}
      ],
      submitBtnState: true,
    }
  },
  validations() {
    return {
      orgName: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required)
      },
      selectedForum: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required)
      },
      orgType: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required)
      }
    }
  },
  computed: {
    ...mapState(authStore, [
      'createOrgState'
    ]),
    computedOrgTypes() {
      return this.orgTypes.map(type => ({
        ...type,
        disabled: typeof type.disabled !== 'undefined' ? this.disabled : undefined
      }))
    },
    modalState: {
      get() {
        return this.createOrgState;
      },
      set() {
        this.closeModal();
      },
    },
    hasChanges() {
      return this.orgName.length > 0
          || !!this.selectedForum
          || this.orgType !== null
          || this.applicantPhone.length > 0
          || this.otherInfo.length > 0
    }
  },
  watch: {
    modalState: {
      handler(val) {
        if (val) {
          this.getForumsAction(1);
          this.getForumsAction(2);
          this.getForumsAction(3);
          this.getForumsAction(4);
        }
      }
    }
  },
  methods: {
    ...mapActions(authStore, [
      'toggleCreateOrgModal',
      'toggleRegisterPartyInfoModal',
      'toggleLeavePageModal',
      'setUnsavedChanges'
    ]),
    ...mapActions(forumSelectStore, [
      'getForumsAction',
      'registerOrganizationAction'
    ]),
    selectForum(forum) {
      this.selectedForum = forum;
      this.toggleForumsSelectTree();
    },
    toggleForumsSelectTree(event) {
      this.$refs.forumsSelect.toggle(event);
    },
    clearForm() {
      this.orgName = '';
      this.selectedForum = '';
      this.orgType = null;
      this.applicantPhone = '';
      this.otherInfo = '';
    },
    closeModal() {
      if (this.hasChanges) {
        this.toggleLeavePageModal();
        this.setUnsavedChanges(true, this.leavePage);
      } else {
        this.leavePage();
      }
    },
    leavePage() {
      this.submitBtnState = true;
      this.toggleCreateOrgModal();
      this.clearForm();
    },

    async send() {
      const isFormValid = await this.v$.$validate()

      if (this.submitBtnState) {
        this.v$.$touch();
        if (isFormValid) {
          const newOrgData = {
            id: null,
            status: "PENDING",
            changeApplication: false,
            showApplication: false,
            forum_name: this.selectedForum.name,
            name: this.orgName,
            forumId: this.selectedForum.id,
            type: this.orgType.code,
            phone: this.applicantPhone,
            otherInfo: this.otherInfo,
            ajax: true
          }
          this.submitBtnState = false;
          this.registerOrganizationAction(newOrgData).then(()=> {
            this.clearForm();
          });
        }
      }
    },
  },
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="create-org-modal"
          modal
          :header="$t('createOrg.modalTitle')"
  >
    <div>
      <div class="modal-title">
        <span class="modal-title__text">
          {{$t('createOrg.organizationInformation')}}
        </span>
      </div>
      <div class="form-group">
        <span class="form-label">
            {{$t('createOrg.name')}}
        </span>
        <div class="field-wrap">
          <input type="text"
                 v-model="orgName"
                 class="form-control"
                 :class="{'form-control--error': v$.orgName.$error}"
                 @focus="orgNameCharCountState = true"
                 @blur="orgNameCharCountState = false"
                 maxlength="60"
                 autofocus
          />
          <span v-if="orgNameCharCountState" class="form-control__char-count">
            {{60 - orgName.length}}
          </span>
        </div>
      </div>

      <div class="form-group">
        <span class="form-label">
          {{$t('createOrg.basedInForum')}}
        </span>
        <div class="field-wrap">
          <div class="forum-select">
            <input type="text"
                   class="form-control forum-select__field"
                   maxlength="60"
                   readonly
                   :placeholder="$t('createOrg.select')"
                   :value="selectedForum.name"
                   :class="{'form-control--error': v$.selectedForum.$error}"
                   @click="toggleForumsSelectTree"
            />
            <font-awesome-icon :icon="['fas', 'angle-down']"
                               class="forum-select__icon"
            >
            </font-awesome-icon>
            <Popover ref="forumsSelect" class="forums-select-popover">
              <forums-select-tree
                  @on-select="selectForum"
              />
            </Popover>
          </div>
        </div>
      </div>

      <div class="form-group">
        <span class="form-label">
          {{$t('createOrg.organizationType')}}
        </span>
        <div class="field-wrap">
          <Select v-model="orgType"
                  :options="computedOrgTypes"
                  optionLabel="name"
                  optionDisabled="disabled"
                  :placeholder="$t('createOrg.select')"
                  overlayClass="create-org-select-overlay"
                  :class="{'p-select--error': v$.orgType.$error}"
          >
            <template #option="slotProps">
              <div class="p-select-option-label-wrap">
               <span class="p-select-option-label"
                     :class="{'disabled': slotProps.option.disabled}"
               >
                 {{ slotProps.option.name }}
               </span>
                <font-awesome-icon v-if="slotProps.option.disabled"
                                   :icon="['fas', 'circle-info']"
                                   class="p-select-option-icon"
                                   @click.stop.prevent="toggleRegisterPartyInfoModal"
                />
              </div>
            </template>
          </Select>
        </div>
      </div>
      <div class="modal-title">
        {{$t('createOrg.contactInformation')}}
      </div>
      <div class="form-group">
        <span class="form-label">
          {{$t('createOrg.phoneToApplicant')}}
        </span>
        <div class="field-wrap">
          <input type="text"
                 v-model="applicantPhone"
                 class="form-control"
                 maxlength="20"
                 @focus="applicantPhoneCharCountState = true"
                 @blur="applicantPhoneCharCountState = false"
          />
          <span v-if="applicantPhoneCharCountState" class="form-control__char-count">
            {{20 - applicantPhone.length}}
          </span>
        </div>
      </div>
      <div class="form-group">
        <span class="form-label">
          {{$t('createOrg.otherInformation')}}
        </span>
        <div class="field-wrap field-wrap--textarea">
          <textarea v-model="otherInfo"
                    cols="30"
                    rows="10"
                    class="form-control form-control--textarea"
                    maxlength="2000"
                    @focus="otherInfoCountState = true"
                    @blur="otherInfoCountState = false"
          />
          <span v-if="otherInfoCountState" class="form-control__char-count">
            {{2000 - otherInfo.length}}
          </span>
        </div>
      </div>
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="closeModal">
        {{ $t('common.buttons.cancel') }}
      </button>
      <button class="p-dialog-primary-btn"
              @click="send"
              :class="{'disabled': !submitBtnState}"
      >
        {{$t('common.buttons.send')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.create-org-modal {
  min-width: 540px;
  width: 540px;

  .modal-title {
    display: flex;
    align-items: center;
    margin: 10px 0 30px;
    color: #4f4f4f;
    font-size: 14px;
    padding: 0 10px;
    white-space: nowrap;

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      margin-right: 15px;
      background: #e2dce7;
    }

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      margin-left: 15px;
      background: #e2dce7;
    }
  }

  .form-group {
    display: flex;
    margin-bottom: 20px;

    .form-label {
      width: 172px;
      margin-right: 15px;
      padding-top: 6px;
      font-size: 12px;
      font-weight: 700;
      text-align: right;
      color: #4f4f4f;
    }
  }

  .field-wrap {
    display: flex;
    align-items: center;
    position: relative;

    .form-control {
      width: 244px;
      max-width: 244px;
      min-width: 205px;
      background: #fff;
      display: inline-block;
      font-size: 12px;
      font-family: 'Open Sans',Arial,sans-serif;
      border: 1px solid #cccccc;
      height: 30px;
      color: #4f4f4f;
      padding: 0 30px 0 8px;
      appearance: none;
      outline: none;

      &--textarea {
        min-height: 105px;
        padding: 8px 10px 15px;
      }

      &::placeholder {
        color: #bbbbbb;
      }

      &:focus {
        border-color: #999999;
        color: #000000;
      }

      &__char-count {
        position: absolute;
        left: 215px;
        top: 5px;
        padding-left: 7px;
        border-left: 1px solid #ccc;
        line-height: 20px;
        font-size: 12px;
        color: #4f4f4f;
      }

      &__desc {
        position: absolute;
        left: 220px;
        font-size: 12px;
        color: #4f4f4f;
      }

      &--error {
        border-color: #fe8f18;
      }
    }

    .form-control-error {
      display: block;
      margin-left: 15px;
      font-size: 12px;
      color: #fe8f18;
    }

    .p-select {
      height: 30px;
      width: 244px;
      border: 1px solid #cccccc;
      font-size: 12px;
      color: #4f4f4f;

      &--error {
        border-color: #fe8f18;
      }

      &.p-focus {
        outline: none;
        border-color: #999999;
      }

      .p-select-label {
        font-size: 12px;
        color: #4f4f4f;

        &.p-placeholder {
          color: #bbbbbb !important;
        }
      }
    }

    &--textarea {
      .form-control__char-count {
        right: 10px;
        bottom: 5px;
        left: auto;
        border: none;
      }
    }
  }

  .forum-select {
    position: relative;

    &__field {
      background: transparent !important;
      cursor: pointer;
      &:hover {
        border: 1px solid #64748b;
      }
    }

    &__icon {
      position: absolute;
      left: 220px;
      top: 7px;
      z-index: -1;
      cursor: pointer;
    }
  }
}

.create-org-select-overlay {
  .p-select-list {
    border: 1px solid #ccc;

    .p-select-option {
      &:hover {
        background: transparent;
      }

      &.p-disabled {
        opacity: 1 !important;
        .p-select-option-icon {
          pointer-events: auto !important;
        }
      }

      .p-select-option-label-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .p-select-option-label {
        font-size: 12px;
        color: #3c3c3c;
        &.disabled {
          color: #999;
        }
      }
      .p-select-option-icon {
        color: #7130a0;
        cursor: pointer;
      }
      &-selected {
        background: #e2e2e2 !important;
      }
    }
  }
}

.forums-select-popover {
  margin: 0 !important;
  &:before,
  &:after {
    content: none !important;
  }
  .p-popover-content {
    padding: 0;
    .forum-tree {
      width: 352px;
      max-height: 380px;
      overflow: auto;
      background: #fff;
      border: 1px solid #ccc;
    }
  }
}
</style>