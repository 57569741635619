<script>
import {mapActions, mapState} from "pinia";
import {useVuelidate} from "@vuelidate/core";
import {minLength, required} from "@vuelidate/validators";
import {proposalStore} from "@/store/proposalStore";
import {documentStore} from "@/store/documentStore";
import SelectButton from 'primevue/selectbutton';
import Editor from '@tinymce/tinymce-vue'

export default {
  name: "EditProposalEnterActual",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    SelectButton,
    Editor
  },
  validations () {
    return this.validationsFields;
  },
  data() {
    return {
      selectedActualDocType: { name: 'Add text', value: 'HTML_TEXT', isDisabled: false },
      actualDocTypes: [
        { name: 'Add text', value: 'HTML_TEXT', isDisabled: false },
        { name: 'Upload PDF', value:  'PDF', isDisabled: false},
      ],
      editorApiKey: 'iznusnmu0tjvdqfe8mexao6gp303nf3pa3l1mmj0vxbogx7n',
      editorConfig: {
        plugins: 'lists link image table code help wordcount',
        height : "240",
        width: "667",
        placeholder: "Enter your actual proposal (min 10 max 4000 characters)",
        menubar: false,
        resize: false,
        branding: false,
        elementpath: false,
        statusbar: false,
        toolbar: 'undo redo | styleselect | bold italic |',
        style_formats: [
          {
            title: "Headers",
            items: [
              {title: "Header 1", format: "h1"},
              {title: "Header 2", format: "h2"}
            ]
          }
        ],
        content_style: `
            body {
              margin: 5px 15px;
              background: #fff;
              font-size: 16px;
              font-weight: 600;
              color: #4f4f4f;
              p {margin: 0;}
            }
        …`
      },
      docFile: null
    }
  },
  computed: {
    ...mapState(proposalStore, [
        'selectedActualType',
        'editedProposalData',
        'actualProposalTextVal',
        'actualProposalFile',
    ]),
    validationsFields() {
      let fields = null;
      if (this.selectedActualType.value === 'HTML_TEXT') {
        fields = {
          actualProposalTextVal: {
            minLength: minLength(10),
            required: required,
          }
        }
      } else if (this.selectedActualType.value === 'PDF') {
        fields = {
          actualProposalFile: {
            required: required,
          }
        }
      }

      return fields;
    },
    modalState: {
      get() {
        return this.registerPartyInfoState;
      },
      set() {
        this.closeModal();
      },
    },
    existingActualProposalType() {
      return this.editedProposalData.actualProposal
          ? this.editedProposalData.actualProposal.type
          : this.selectedActualType;
    },
    actualProposalType: {
      get() {
        return this.selectedActualType;
      },
      set(val) {
        this.setActualProposalType(val);
      },
    },
    actualProposalText: {
      get() {
        return this.actualProposalTextVal;
      },
      set(val) {
        this.setActualProposalText(val);
      },
    }
  },
  methods: {
    ...mapActions(proposalStore, [
        "setAsModified",
        "setActualProposalType",
        "setActualProposalText",
        "setActualProposalFile",
        "toggleActualExamplesModal"
    ]),
    ...mapActions(documentStore, [
        'getTextDocumentContent',
        'downloadAttachment'
    ]),
    actualProposalTextChanged() {
      this.setAsModified('actualProposalHasChanged');
    },
    selectFile(event) {
      this.setAsModified('actualProposalHasChanged');
      this.setActualProposalFile(event.target.files);
    },
    downloadActualProposalFiled() {
      if (this.actualProposalFile.id) {
        this.downloadAttachment(this.actualProposalFile.hash).then((response) => {
          window.open(URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'})));
        });
      } else {
        const uploadedFile = document.getElementById('actualProposalFileField').files[0];

        window.open(URL.createObjectURL( new Blob([uploadedFile], {type: 'application/pdf'})));
      }
    },
    clearActualProposalFile() {
      this.setActualProposalFile(null);
    },
  },
  beforeMount() {
    const contentId = this.editedProposalData.actualProposal ? this.editedProposalData.actualProposal.contentId : null;

    if (contentId) {
      if (this.existingActualProposalType) {
        this.actualDocTypes.forEach((item)=> {
          if (item.value === this.existingActualProposalType) {
            this.setActualProposalType(item);
          } else {
            item.isDisabled = true;
          }
        });
      }

      if (this.existingActualProposalType === 'HTML_TEXT') {
        this.getTextDocumentContent(contentId).then((resp)=> {
          this.setActualProposalText(resp.data);
        });
      } else if (this.existingActualProposalType === 'PDF') {
        const attachment = this.editedProposalData.actualProposal.attachments[0];
        this.setActualProposalFile(attachment);
      }
    }
  },
  beforeUnmount() {
    this.clearActualProposalFile();
  }
}
</script>

<template>
  <div class="edit-prop-section enter-actual-section">
    <div class="editor-wrap"
         :class="{'editor-wrap--error': v$.$error}"
    >
      <span class="edit-prop-section__title">
        <span>1. Enter actual proposal</span>
        <SelectButton v-model="actualProposalType"
                      :options="actualDocTypes"
                      optionLabel="name"
                      optionDisabled="isDisabled"
                      :allowEmpty="false"
                      aria-labelledby="basic"
        />
      </span>
      <div v-if="selectedActualType.value === 'HTML_TEXT'">
        <Editor
            v-model="actualProposalText"
            :api-key="editorApiKey"
            :init="editorConfig"
            @keyup="actualProposalTextChanged"
            @focusout="v$.actualProposalTextVal.$reset"
        />
      </div>
      <div v-else-if="selectedActualType.value === 'PDF'">
        <div class="pdf-uploader-wrap">
            <input v-show="!actualProposalFile"
                   type="file"
                   id="actualProposalFileField"
                   @change="selectFile"
            >
            <div v-show="actualProposalFile" class="file-controls">
              <span class="download-button" @click="downloadActualProposalFiled">
               Download actual proposal PDF
             </span>
              <i class="fas fa-times remove-button"
                 @click="clearActualProposalFile"
                 title="Remove file"
              ></i>
            </div>
        </div>
      </div>
    </div>
    <div class="tutorial-sidebar">
      <div class="actual-description">
        <div class="actual-description__title">
          What to include:
        </div>
        <ul>
          <li class="actual-description__text">
            Your actual proposal text shall describe what is actually voted for
          </li>
          <li class="actual-description__text">
            Your arguments of why your proposal is needed is entered later
          </li>
          <li class="actual-description__text">
            Your text has to be in a language that is approved by forum Riksdagen: <strong>Swedish</strong>
          </li>
        </ul>
      </div>
      <div class="actual-examples-control" @click="toggleActualExamplesModal">
        See examples of used actual proposal text
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.enter-actual-section {
  display: flex;
  padding: 0 !important;
  background: transparent !important;
  box-shadow: none !important;

  .edit-prop-section__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 15px 10px;
    padding: 15px 0;
    border-bottom: 1px solid #f2f2f2;
  }
  .editor-wrap {
    display: flex;
    flex-direction: column;
    width: 667px;
    max-width: 667px;
    margin-right: 10px;
    background: #fff;

    &--error {
      outline: solid 2px #e60907;
    }

    .p-selectbutton {
      background: #F1F5F9;

      .p-togglebutton {
        height: 24px;
        padding: 7px 14px;
        .p-togglebutton-label {
          font-size: 12px;
        }
      }
      .p-togglebutton-checked {
        background: #7835a6;
      }
    }

    .tox-tinymce {
      border: 0;
      .tox-edit-area {
        &:before {
          border: none;
        }
      }
    }

    .tox-editor-header {
      z-index: 0 !important;
    }

    .pdf-uploader-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;

      .file-controls {
        display: flex;

        .download-button {
          margin-right: 15px;
          font-weight: 600;
          cursor: pointer;
          color: #7835a6;
        }
        .remove-button {
          cursor: pointer;
          color: red;
        }
      }
    }
  }

  .tutorial-sidebar {
    width: 293px;
    .actual-description {
      width: 100%;
      margin-bottom: 10px;
      border-radius: 9px;
      background-color: #ffffff;
      color: #4f4f4f;
      padding: 16px 0 10px;

      &__title {
        position: relative;
        padding: 0 10px 0 38px;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 600;

        &:before {
          content:'';
          position: absolute;
          top: 0;
          left: 11px;
          width: 18px;
          height: 18px;
          display: block;
          background: url("~@/assets/img/proposal/check.svg"), no-repeat, center;
        }
      }
      &__text {
        position: relative;
        padding: 0 10px 0 28px;
        margin-bottom: 17px;
        font-size: 14px;
        line-height: 18px;
        &:before {
          content:'';
          position: absolute;
          top: 6px;
          left: 11px;
          width: 6px;
          height: 6px;
          display: block;
          background-color: #4f4f4f;
          border-radius: 6px;
        }
      }
    }
    .actual-examples-control {
      display: flex;
      align-items: center;
      position: relative;
      height: 68px;
      width: 100%;
      padding: 0 0 0 65px;
      border-radius: 9px;
      background-color: #ffffff;
      font-weight: 600;
      color: #7835a6;
      cursor: pointer;
      box-sizing: border-box;
      line-height: 18px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 12px;
        width: 36px;
        height: 36px;
        background: url("~@/assets/img/proposal/proposal.svg"), no-repeat, center;
        background-size: 100%;
      }
    }
  }
}
</style>