<script>
import AVATAR_SIZE from './constants';

export default {
  name: 'BaseAvatar',
  props: {
    size: {
      required: true,
    },
    imgHash: {
      required: false,
    },
    imgSrc: {
      required: false,
    },
    imgType: {
      type: String,
    },
  },
  data() {
    return {
      AVATAR_SIZE,
    };
  },
  computed: {
    imgLink() {
      return this.imgHash
        ? `/viewimage?type=${this.imgType ? this.imgType : 'user'}&sh=${this.imgHash}` : '';
    },
    avatarSizeClass() {
      return `avatar-size_${this.AVATAR_SIZE[this.size]}`;
    },
  },
};
</script>

<template>
  <div :class="avatarSizeClass" class="avatar">
    <img v-if="imgHash" :src="imgLink" class="avatar__img"/>
    <img v-else :src="imgSrc" class="avatar__img"/>
  </div>
</template>

<style scoped lang="scss">
  .avatar {
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(79, 79, 79, 0.25);
  }

  .avatar-size_18 {
    width: 18px;
    min-width: 18px;
    height: 18px;
    border-radius: 18px;
    .avatar__img {
      width: 18px;
      height: auto;
    }
  }

  .avatar-size_21 {
    width: 21px;
    min-width: 21px;
    height: 21px;
    border-radius: 21px;
    .avatar__img {
      width: 21px;
      height: auto;
    }
  }

  .avatar-size_24 {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 24px;
    .avatar__img {
      width: 24px;
      height: auto;
    }
  }

  .avatar-size_26 {
    width: 26px;
    min-width: 26px;
    height: 26px;
    border-radius: 26px;
    .avatar__img {
      width: 26px;
      height: auto;
    }
  }

  .avatar-size_28 {
    width: 28px;
    min-width: 28px;
    height: 28px;
    border-radius: 28px;
    .avatar__img {
      width: 28px;
      height: auto;
    }
  }

  .avatar-size_30 {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 30px;
    .avatar__img {
      width: 30px;
      height: auto;
    }
  }

  .avatar-size_32 {
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 32px;
    .avatar__img {
      width: 32px;
      height: auto;
    }
  }

  .avatar-size_44 {
    width: 44px;
    min-width: 44px;
    height: 44px;
    border-radius: 44px;
    .avatar__img {
      width: 44px;
      height: auto;
    }
  }

  .avatar-size_64 {
    width: 64px;
    min-width: 64px;
    height: 64px;
    border-radius: 64px;
    .avatar__img {
      width: 64px;
      height: auto;
    }
  }
</style>
