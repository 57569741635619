<script>
import {mapActions, mapState} from "pinia";
import {opinionStore} from "@/store/opinionStore";
import Popover from "primevue/popover";
import OpinionPrivacySelect from "@/views/opinion/OpinionPrivacySelect.vue";

export default {
  name: "ProposalActionOpinionControl",
  props: {
    disabledState: {
      type: Boolean
    }
  },
  components: {
    Popover,
    OpinionPrivacySelect,
  },
  data() {
    return {
      additionalViewState: false,
      opinionSelectSecondStepState: false,
    }
  },
  computed: {
    ...mapState(opinionStore, ['addArgumentsModalState']),
  },
  methods: {
    ...mapActions(opinionStore, ['toggleAddArgumentsModalState']),
    toggleOpinionPopover(event) {
      if (!this.disabledState) {
        this.$refs.opinionPopover.toggle(event);
      }
    },
    goToFirstStep() {
      this.opinionSelectSecondStepState = false;
    },
    toggleAdditionalView() {
      this.additionalViewState = !this.additionalViewState;
    },
    selectOpinionReaction(reaction) {
      this.opinionData.opinionLike = reaction;
      this.opinionSelectSecondStepState = true;
    },
    changePrivacy(privacyVal) {
      this.opinionData.opinionPrivacy = privacyVal;
    },
    showOpinionDuplicateModal() {
      console.log('showOpinionDuplicateModal');
    },
    onFocusImportanceItem(itemNumber) {
      console.log(itemNumber)
    },
    submitOpinion() {
      console.log('submitOpinion');
    }
  }
}
</script>

<template>
  <button type="button"
          class="enter-opinion-btn"
          :class="{'enter-opinion-btn--disabled': disabledState}"
          @click="toggleOpinionPopover"
  >
    <i class="enter-opinion-btn__icon"></i>
    <span class="enter-opinion-btn__text">
            {{$t('opinion.enterYourOpinion')}}
          </span>
  </button>
  <Popover ref="opinionPopover"
           class="opinion-popover"
  >
    <template v-if="!additionalViewState">
      <template v-if="!opinionSelectSecondStepState">
        <ul class="opinion-reaction-list">
          <li class="opinion-reaction-item opinion-reaction-item--very-good"
              @click="selectOpinionReaction('VERY_GOOD')"
          >
            <div class="opinion-reaction-item__tooltip">
              {{$t('opinion.veryGood')}}
            </div>
          </li>
          <li class="opinion-reaction-item opinion-reaction-item--pretty-good"
              @click="selectOpinionReaction('PRETTY_GOOD')"
          >
            <div class="opinion-reaction-item__tooltip">
              {{$t('opinion.prettyGood')}}
            </div>
          </li>
          <li class="opinion-reaction-item opinion-reaction-item--no-opinion"
              @click="selectOpinionReaction('NO_OPINION')"
          >
            <div class="opinion-reaction-item__tooltip">
              {{$t('opinion.noOpinion')}}
            </div>
          </li>
          <li class="opinion-reaction-item opinion-reaction-item--pretty-bad"
              @click="selectOpinionReaction('PRETTY_BAD')"
          >
            <div class="opinion-reaction-item__tooltip">
              {{$t('opinion.prettyBad')}}
            </div>
          </li>
          <li class="opinion-reaction-item opinion-reaction-item--very-bad"
              @click="selectOpinionReaction('VERY_BAD')"
          >
            <div class="opinion-reaction-item__tooltip">
              {{$t('opinion.veryBad')}}
            </div>
          </li>
          <li class="opinion-reaction-item opinion-reaction-item--more"
              @click="toggleAdditionalView"
          >
            <div class="opinion-reaction-item__tooltip">
              {{$t('opinion.duplicatingOrIncomplete')}}
            </div>
          </li>
        </ul>
      </template>
      <template v-else>
        <div class="opinion-settings-step">
          <div class="prev-step-control-wrap">
            <font-awesome-icon :icon="['fas', 'arrow-left']"
                               class="prev-step-control"
                               @click="goToFirstStep"
            ></font-awesome-icon>
          </div>
          <div class="importance-settings-wrap">
            <div class="importance-settings">
                  <span class="importance-settings__title">
                    {{importanceSettingsTitle}}
                  </span>
              <ul class="importance-settings__list">
                <li class="importance-settings-item"
                    @mouseover="onFocusImportanceItem(1)"
                ></li>
                <li class="importance-settings-item"
                    @mouseover="onFocusImportanceItem(2)"
                ></li>
                <li class="importance-settings-item"
                    @mouseover="onFocusImportanceItem(3)"
                ></li>
                <li class="importance-settings-item"
                    @mouseover="onFocusImportanceItem(4)"
                ></li>
                <li class="importance-settings-item"
                    @mouseover="onFocusImportanceItem(5)"
                ></li>
              </ul>
            </div>
          </div>
          <div class="privacy-settings-wrap">
            <opinion-privacy-select
                :privacy="opinionData.opinionPrivacy"
                @on-change="changePrivacy"
            ></opinion-privacy-select>
          </div>
          <div class="arguments-settings">
            <span class="enter-arguments-label"
                  @click="toggleAddArgumentsModalState"
            >
              {{$t('opinion.enterArguments')}}
            </span>
          </div>
          <div class="finish-control-wrap">
            <span class="finish-control"
                  @click="submitOpinion"
            >
              Done
            </span>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <ul class="opinion-additional-reaction-list">
        <li class="opinion-reaction-item opinion-reaction-item--control"
            @click="toggleAdditionalView"
        >
          <font-awesome-icon :icon="['fas', 'arrow-left']"
                             class="opinion-reaction-item__control"
          ></font-awesome-icon>
        </li>
        <li class="opinion-reaction-item">
          <i class="opinion-reaction-item__icon opinion-reaction-item__icon--incomplete"></i>
          <span class="opinion-reaction-item__text">
                  {{$t('opinion.incomplete')}}
                </span>
        </li>
        <li class="opinion-reaction-item"
            @click="showOpinionDuplicateModal"
        >
          <i class="opinion-reaction-item__icon opinion-reaction-item__icon--duplicate"></i>
          <span class="opinion-reaction-item__text">
                  {{$t('opinion.duplicate')}}
                </span>
        </li>
      </ul>
    </template>
  </Popover>
</template>

<style lang="scss" scoped>
.enter-opinion-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  padding: 0 12px;
  border-radius: 4px;
  background-color: #7835a6;
  color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none;

  &__icon {
    width: 30px;
    height: 30px;
    margin-right: 6px;
    background: url("~@/assets/img/opinion/without_opinion.svg") no-repeat;
    background-size: cover;
  }
  &__text {
    font-size: 16px;
    font-weight: 600;
  }

  &--disabled {
    background-color: #909090 !important;
    cursor: default;
  }
}

.opinion-popover {
  border-radius: 31px !important;
  box-shadow: 0 2px 12px 0 rgba(215,215,215,.5) !important;
  .p-popover-content {
    min-width: 256px;
    height: 44px;
    padding: 0;
  }
  .opinion-reaction-list {
    display: flex;
    align-items: center;
    padding: 0 2px;

    .opinion-reaction-item {
      width: 42px;
      height: 42px;
      position: relative;
      background-size: 30px !important;
      background-position: center !important;
      cursor: pointer;

      &--very-good {
        background: url("~@/assets/img/opinion/very_good.svg") no-repeat;
      }
      &--pretty-good {
        background: url("~@/assets/img/opinion/pretty_good.svg") no-repeat;
      }
      &--no-opinion {
        background: url("~@/assets/img/opinion/no_opinion.svg") no-repeat;
      }
      &--pretty-bad {
        background: url("~@/assets/img/opinion/pretty_bad.svg") no-repeat;
      }
      &--very-bad {
        background: url("~@/assets/img/opinion/very_bad.svg") no-repeat;
      }
      &--more {
        background: url("~@/assets/img/opinion/emoji-more.svg") no-repeat;
      }
      &:hover {
        background-size: 42px !important;

        .opinion-reaction-item__tooltip {
          display: block;
        }
      }

      &__tooltip {
        position: absolute;
        top: -28px;
        left: 50%;
        transform: translate(-50%);
        min-width: 70px;
        text-align: center;
        background: #4f4f4f;
        font-size: 12px;
        line-height: 12px;
        color: #ffffff;
        white-space: nowrap;
        border-radius: 8px;
        padding: 3px 7px;
        display: none;
        z-index: 10;
      }
    }
  }
  .opinion-additional-reaction-list {
    display: flex;
    align-items: center;
    .opinion-reaction-item {
      display: flex;
      align-items : center;
      margin-right: 5px;
      cursor: pointer;
      &:hover {
        .opinion-reaction-item__icon {
          background-size: 42px !important;
        }
      }

      &__icon {
        display: flex;
        width: 42px;
        height: 42px;
        margin-right: 3px;
        position: relative;
        background-size: 30px !important;
        background-position: center !important;
        cursor: pointer;

        &:hover {
          background-size: 42px !important;
        }

        &--incomplete {
          background: url("~@/assets/img/opinion/incomplete.svg") no-repeat;
        }

        &--duplicate {
          background: url("~@/assets/img/opinion/duplicate.svg") no-repeat;
        }
      }
      &__text {
        font-size: 12px;
        color: #7835a6;
      }
      &--control {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 32px;
        margin-left: 10px;
        padding-right: 3px;
        border-right: 1px solid #f2f2f2;

        &:hover {
          cursor: pointer;
          .opinion-reaction-item__control {
            font-size: 18px;
          }
        }

        .opinion-reaction-item__control {
          font-size: 14px;
          color: #7835a6;
          cursor: pointer;
        }
      }
    }
  }

  .prev-step-control-wrap {
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    .prev-step-control {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      padding-right: 3px;
      font-size: 14px;
      color: #7835a6;
      cursor: pointer;

      &:hover {
        font-size: 18px;
      }
    }
  }

  .opinion-settings-step {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
  }

  .importance-settings-wrap {
    display: flex;
    align-items: center;
    height: 35px;
    border-left: solid 1px #f2f2f2;
    .importance-settings {
      padding: 0 10px;
      &__title {
        margin-bottom: 5px;
        font-size: 10px;
        line-height: 10px;
        color: #bbbbbb;
      }
      &__list {
        display: flex;
      }
      .importance-settings-item {
        width: 11px;
        height: 10px;
        margin: 0 1px;
        background: url("~@/assets/img/opinion/sprite-heart.svg") no-repeat;
        background-position: -17px -17px;
      }
    }
  }

  .privacy-settings-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    min-width: 96px;
    height: 35px;
    border-left: solid 1px #f2f2f2;
  }

  .arguments-settings {
    display: flex;
    align-items: center;
    height: 35px;
    padding: 0 10px;
    border-left: solid 1px #f2f2f2;
    .enter-arguments-label {
      display: block;
      position: relative;
      font-size: 12px;
      line-height: 12px;
      padding-left: 15px;
      color: #7835a6;
      cursor: pointer;
      &:before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        left: 0;
        background: url("~@/assets/img/opinion/mode_edit.svg") no-repeat;
        background-size: 100%;
      }
    }
  }

  .finish-control-wrap {
    display: flex;
    align-items: center;
    height: 35px;
    padding: 0 5px;
    border-left: solid 1px #f2f2f2;
    .finish-control {
      display: flex;
      align-items: center;
      height: 20px;
      padding: 0 12px;
      border-radius: 14.5px;
      background-color: #7835a6;
      font-size: 12px;
      font-weight: 600;
      color: #fff;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

}
</style>