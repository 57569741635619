<script>
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import Dialog from "primevue/dialog";
export default {
  name: "UserWithEmailNotExistModal",
  components: {
    Dialog
  },
  computed: {
    ...mapState(authStore, [
      'userWithEmailNotExistModalState'
    ]),
    modalState: {
      get() {
        return this.userWithEmailNotExistModalState;
      },
      set() {
        this.closeModal();
      },
    },
  },
  methods: {
    ...mapActions(authStore, [
      'toggleUserWithEmailNotExistModal'
    ]),
    closeModal() {
      this.toggleUserWithEmailNotExistModal();
    }
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="reset-password-modal"
          modal
          :header="$t('validations.error')"
  >
    <div>
      {{$t('auth.sendPassword.changePasswordRequestSent')}}
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="closeModal()">
        {{$t('common.buttons.close')}}
      </button>
    </template>
  </Dialog>
</template>

<style scoped>

</style>