<script>
import i18n from '@/i18n';
import Dialog from "primevue/dialog";
import ProposerItem from "@/components/ProposerItem.vue";
import ProposalGeneralSubjectsList from "@/views/proposal/proposal-general-info/ProposalGeneralSubjectsList.vue";
import {mapActions} from "pinia";
import {proposalStore} from "@/store/proposalStore";
import {documentStore} from "@/store/documentStore";
import {baseLoaderStore} from "@/store/baseLoaderStore";

export default {
  name: "ProposalGeneralInfoAdditional",
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  components: {
    Dialog,
    ProposerItem,
    ProposalGeneralSubjectsList
  },
  data() {
    return {
      missingActualProposalModalState: false,
      subjectsListState: false
    }
  },
  computed: {
    showMoreSpokesControl() {
      return this.info.extraAttributes.spokes.length > 4;
    },
    hasActualProposal() {
      return this.info.actualProposal;
    },
    hasSpokes() {
      return this.info.extraAttributes.spokes
          ? this.info.extraAttributes.spokes.length > 0 : null;
    },
    shortSpokesList() {
      return this.info.extraAttributes.spokes.slice(0, 4)
    },
    subjectsListData() {
      return this.info.subjects;
    },
    subjectsControlLabel() {
      return this.subjectsListState
          ? i18n.global.t('proposal.generalInfo.closeSubjects')
          : `${i18n.global.t('proposal.generalInfo.seeSubjects')} (${this.info.subjects.length})`;
    }
  },
  methods: {
    ...mapActions(proposalStore, [
        'toggleActualProposalModal',
        'toggleProposersModal'
    ]),
    ...mapActions(documentStore, [
      'downloadAttachment',
    ]),
    ...mapActions(baseLoaderStore, [
      'showBaseLoader',
      'hideBaseLoader'
    ]),
    switchSubjectsList() {
      this.subjectsListState = !this.subjectsListState;
    },
    openRelatedProposals() {
      alert('To be implemented')
    },
    openActualProposalModal() {
      if (this.hasActualProposal) {
        this.toggleActualProposalModal();
      }
    },
    showAllSpokesDialog() {
      this.toggleProposersModal();
    },
    openDocument() {
      const attachmentsHash = this.info.extraAttributes.mainSource.attachments[0].hash;

      this.showBaseLoader();
      this.downloadAttachment(attachmentsHash).then((resp)=> {
        const file = new Blob([resp.data], {type: 'application/pdf'});
        this.hideBaseLoader();
        window.open(URL.createObjectURL(file));
      })
    },
  }
}
</script>

<template>
  <div class="proposal-general-info-additional">
    <div v-if="hasSpokes" class="proposal-proposers">
      <span class="proposal-proposers__label">
        {{$t('proposal.generalInfo.proposersAndSpokes')}}
      </span>
      <div class="proposal-proposers__list">
        <proposer-item v-for="item in shortSpokesList"
                       :key="item.id"
                       :item-data="item"
        ></proposer-item>
      </div>
      <span v-if='showMoreSpokesControl'
            @click="showAllSpokesDialog"
            class="proposal-proposers__more-link"
      >
        {{$t('proposal.generalInfo.moreInfo')}}
      </span>
    </div>
    <div class="proposal-controls">
      <div class="proposal-controls__group">
        <button type="button" class="control-button actual-proposal"
                :class="{'disabled': !hasActualProposal}"
                @click="openActualProposalModal"
        >
          <i class="control-button__icon"></i>
          <span class="control-button__caption">
            {{$t('proposal.generalInfo.actualProposal')}}
          </span>
          <i v-if="!hasActualProposal"
             class="info-icon"
             @click="missingActualProposalModalState = true"
          ></i>
        </button>
        <button type="button"
                v-tooltip.top="$t('proposal.generalInfo.noArgumentWasCreated')"
                class="control-button proposer-arguments disabled">
          <i class="control-button__icon"></i>
          <span class="control-button__caption">
             {{$t('proposal.generalInfo.proposerArguments')}}
          </span>
        </button>
      </div>
      <div class="proposal-controls__group">
        <button type="button"
                @click="openRelatedProposals"
                class="control-button"
        >
          <span class="control-button__caption">
            {{$t('proposal.generalInfo.relatedProposals')}}
          </span>
        </button>
        <button type="button"
                @click="switchSubjectsList"
                class="control-button"
        >
          <span class="control-button__caption">
            {{ subjectsControlLabel }}
          </span>
        </button>
      </div>
    </div>

    <transition name="slidedown">
      <proposal-general-subjects-list
          v-if="subjectsListState"
          :subjects-list="subjectsListData"
      ></proposal-general-subjects-list>
    </transition>

    <Dialog v-model:visible="missingActualProposalModalState"
            modal
            :header="$t('proposal.generalInfo.actualProposal')"
            :style="{ 'min-width': '550px' }"
            class="missing-actual-modal"
    >
      <div class="p-10">
        <template v-if="info.extraAttributes.contentStatus === 'PROCESSING'">
          {{$t('proposal.generalInfo.actualToBeEntered.p1')}}
          <a @click="openDocument">
            {{$t('proposal.generalInfo.actualToBeEntered.link')}}
          </a>
        </template>
        <template v-else>
          {{$t('proposal.generalInfo.actualNotProvided')}}
        </template>
      </div>
      <template #footer>
        <button type="button" class="p-dialog-close-btn" @click="missingActualProposalModalState = false">
          {{$t('common.buttons.close')}}
        </button>
      </template>
    </Dialog>

  </div>
</template>

<style lang="scss" scoped>

.proposal-general-info-additional {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 15px;
  padding: 0 0 20px;

  .proposal-proposers {
    display: flex;
    margin-bottom: 20px;
    &__label {
      max-width: 97px;
      margin-right: 5px;
      line-height: 1.5;
      letter-spacing: -.01px;
      font-weight: 600;
      font-size: 12px;
    }
    &__list {
      display: flex;
      margin-right: 15px;
    }
    &__more-link {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 600;
      color: #7835a6;
      cursor: pointer;
    }
  }

  .proposal-controls {
    display: flex;
    justify-content: space-between;

    &__group {
      .control-button {
        &:first-child {
          margin-right: 10px;
        }
      }
    }

    .control-button {
      display: inline-flex;
      padding: 0 15px;
      align-items: center;
      height: 40px;
      max-width: 383px;
      border-radius: 4px;
      box-shadow: 0 1px 4px 0 rgba(79, 79, 79, .21);
      background: #fff;
      cursor: pointer;
      border: 1px solid transparent;
      outline: none;

      &:hover {
        background: #f2f2f2;
      }

      &.disabled {
        border: 1px solid #d1d1d1;
        box-shadow: none;
        cursor: default;
        &:hover {
          background: #ffffff;
          cursor: default;
        }
        .control-button__caption {
          color: #bbbbbb;
        }
      }

      &__icon {
        display: block;
        width: 24px;
        min-width: 24px;
        height: 24px;
        margin-right: 10px;
      }

      &__caption {
        font: 14px/18px 'Open Sans',Arial,sans-serif;
        font-weight: 600;
        color: #7835a6;
        line-height: 1.1;
      }

      &.actual-proposal {
        .control-button__icon {
          background: url("~@/assets/img/proposal/proposal.svg"), no-repeat, center;
        }
        &.disabled {
          .control-button__icon {
            background: url("~@/assets/img/proposal/proposal-disabled.svg"), no-repeat, center;
          }
        }

        .info-icon {
          width: 18px;
          height: 18px;
          margin-left: 5px;
          background: url("~@/assets/img/proposal/info-icon-purple.svg"), no-repeat, center;
          cursor: pointer;
        }
      }

      &.proposer-arguments {
        .control-button__icon {
          background: url("~@/assets/img/proposal/why-needed.svg"), no-repeat, center;
        }
        &.disabled {
          .control-button__icon {
            background: url("~@/assets/img/proposal/why-needed-disabled.svg"), no-repeat, center;
          }
        }
      }


    }
  }
}

</style>