<script>
import {mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";
import {argumentStore} from "@/store/argumentStore";
import BaseAvatar from "@/components/BaseAvatar/BaseAvatar.vue";
import {formatZonedDateTime} from "@/baseUtils";

export default {
  name: "ProposalArgumentInfo",
  components: {
    BaseAvatar
  },
  computed: {
    ...mapState(argumentStore, [
      'activeArgument'
    ]),
    ...mapState(proposalStore, [
        'proposalInfo'
    ]),
    argumentCreatedDate() {
      return formatZonedDateTime(this.activeArgument.dateTime);
    }
  }
}
</script>

<template>
  <div class="argument-info">
    <div class="argument-info__icon"></div>
    <div class="argument-info__text">
      <p>{{activeArgument.title}}</p>
      <p><span class="text-label">to proposal: {{proposalInfo.title}}</span></p>
    </div>
    <div class="argument-metadata">
      <div class="argument-metadata__icon"></div>
      <div class="argument-metadata__block">
        <div class="arg-metadata-info-row">
          <span class="arg-metadata-text">{{activeArgument.title}}</span>
        </div>
        <div class="arg-metadata-info-row">
          <span class="arg-metadata-label">to proposal:</span>
          <span class="arg-metadata-text">{{proposalInfo.title}}</span>
        </div>
        <div class="arg-metadata-info-row creator-info">
          <span class="arg-metadata-label">argument by:</span>
          <base-avatar :size="'32'"
                       class="creator-info__avatar"
                       :img-hash="activeArgument.creator.imgHash"
          />
          <router-link :to="{name: 'UserView', params: {id: activeArgument.creator.id}}"
                       target="_blank"
                       class="creator-info__name"
          >{{ activeArgument.creator.name }}</router-link>
        </div>
        <div class="arg-metadata-info-row">
          <span class="arg-metadata-label">Created: {{argumentCreatedDate}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.argument-info {
  display: flex;
  align-items: center;
  &__icon {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    background: url("~@/assets/img/arguments/pp-logo-video.svg") no-repeat;
  }
  &__text {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    .text-label {
      font-weight: 400;
    }
  }

  .argument-metadata {
    position: absolute;
    z-index: 10;
    top: 12px;
    right: 12px;
    cursor: pointer;
    &__icon {
      position: relative;
      z-index: 10;
      width: 20px;
      height: 20px;
      background: url("~@/assets/img/arguments/info-icon.svg") no-repeat;
    }
    &__block {
      position: absolute;
      z-index: 5;
      top: -10px;
      left: -570px;
      flex-direction: column;
      width: 596px;
      padding: 5px 50px 15px 10px;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0 2px 4px 0 rgba(79, 79, 79, 0.21);
      box-sizing: border-box;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.3s, opacity 0.3s linear;

      .arg-metadata-info-row {
        margin-bottom: 5px;
      }

      .arg-metadata-label {
        font-size: 12px;
        line-height: 1.5;
      }

      .arg-metadata-text {
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
      }

      .creator-info {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &__avatar {
          margin: 0 5px;
        }
        &__name {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }

    &:hover {
      .argument-metadata__icon {
        background: url("~@/assets/img/arguments/info-icon-dark.svg") no-repeat;
      }
      .argument-metadata__block {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

</style>