<script>
import {mapActions, mapState} from 'pinia'
import { baseLoaderStore} from "@/store/baseLoaderStore";
import BaseHeader from "@/components/BaseHeader.vue";
import {authStore} from "@/store/authStore";

export default {
  name: "BaseIframe",
  props: {
    src: {
      type: String,
      required: true
    },
    height: {
      type: Number
    },
    useSandbox: {
      type: Boolean,
      default: true
    },
    disableLoader: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BaseHeader
  },
  computed: {
    ...mapState(authStore, [
        'userActivated',
        'showCookiePolicyMessage'
    ]),
    sanBoxOptions() {
      return 'allow-scripts allow-forms allow-popups allow-downloads allow-same-origin allow-top-navigation allow-popups-to-escape-sandbox';
    },
    frameClass() {
      var frameClass = '';

      if (!this.showHeader) {
        frameClass +=' without-header';
      } else {
        if (!this.userActivated) {
          frameClass += ' not-approved-user-header-message';
        } else if (this.showCookiePolicyMessage) {
          frameClass += ' not-accepted-cookie-header-message';
        }
      }

      return frameClass;
    }
  },
  methods: {
    ...mapActions(baseLoaderStore, ['hideBaseLoader']),
    setTitleToParentFrame() {
      const frameTitle = document.getElementById('baseIframe')
          ? document.getElementById('baseIframe').contentDocument.title
          : '';
      window.document.title = frameTitle;
    }
  },
  mounted() {
    if (!this.disableLoader) {
      document.getElementById('baseIframe').onload = () => {
        this.hideBaseLoader();
        this.setTitleToParentFrame();
      };
    }
  }
}
</script>

<template>
  <base-header v-if="showHeader"/>
  <template v-if="useSandbox">
    <iframe
        ref="myIframe"
        id="baseIframe"
        :src="src"
        :sandbox="sanBoxOptions"
        :height="height ? height : 'auto'"
        :class="frameClass"
    ></iframe>
  </template>
  <template v-else>
    <iframe
        ref="myIframe"
        id="baseIframe"
        :src="src"
        :height="height ? height : 'auto'"
        :class="frameClass"
    ></iframe>
  </template>
</template>

<style lang="scss" scoped>
iframe {
  position: absolute;
  top: 70px;
  left: 0;
  height: calc(100vh - 70px);
  width: 100%;
  border: none;
  box-sizing: border-box;

  &.without-header {
    top: 0;
    height: calc(100vh - 3px);
  }

  &.not-approved-user-header-message {
    top: 115px;
    height: calc(100vh - 115px);
  }

  &.not-accepted-cookie-header-message {
    top: 150px;
    height: calc(100vh - 150px);
  }
}
</style>