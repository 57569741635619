<script>
import Dialog from "primevue/dialog";
import {mapActions, mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";

export default {
  name: "ProposalSavedDraftModal",
  components: {
    Dialog
  },
  computed: {
    ...mapState(proposalStore, [
        'savedDraftModalState'
    ]),
    modalState: {
      get() {
        return this.savedDraftModalState;
      },
      set() {
        this.closeModal();
      },
    },
  },
  methods: {
    ...mapActions(proposalStore, [
        'toggleSavedDraftModalState'
    ]),
    closeModal() {
      this.toggleSavedDraftModalState();
    }
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="saved-draft-modal"
          modal
          :header="$t('proposal.savedDraftModal.title')"
  >
    <div class="saved-draft-modal__body">
      <div class="info-item">
        <font-awesome-icon :icon="['fas', 'check']"
                           class="info-item__icon check"
        />
        <span class="info-item__text">
          {{$t('proposal.savedDraftModal.p1')}}
        </span>
      </div>
      <div class="info-item">
        <font-awesome-icon :icon="['fas', 'circle-info']"
                           class="info-item__icon info"
        />
        <span class="info-item__text">
          {{$t('proposal.savedDraftModal.p2')}}
        </span>
      </div>
    </div>
    <template #footer>
      <button class="p-dialog-primary-btn"
              @click="closeModal"
      >
        {{$t('common.buttons.ok')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss" >
.saved-draft-modal {
  &__body {
    width: 400px;
    .info-item {
      display: flex;
      margin-bottom: 10px;
      &__icon {
        margin-right: 10px;
        font-size: 14px;
        &.check {
          color: #43AF18;
        }
        &.info {
          color: #7835A6;
        }
      }
      &__text {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
</style>