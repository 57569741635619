import {defineStore} from 'pinia';
import axios from 'axios';
import {proposalStore} from "@/store/proposalStore";

export const argumentStore = defineStore({
    id: 'argumentStore',
    state: () => ({
        activeArgument: null,
        activeArgumentIndex: '',
        currentArgumentRating: null,
        argRatings: null,
        argRatingCount: {
            agree: 0,
            disagree: 0
        },
        videoDurationErrorModalState: false
    }),
    getters: {
        isFirstArgument() {
            return this.activeArgumentIndex === 0 || proposalStore().proposalArguments.findLastIndex((a, idx) => !!a.argumentDetailed && idx < this.activeArgumentIndex, this) < 0;
        },
        isLastArgument() {
            return this.activeArgumentIndex === (proposalStore().proposalArguments.length - 1) || proposalStore().proposalArguments.findIndex((a, idx) => !!a.argumentDetailed && idx > this.activeArgumentIndex, this) < 0;
        },
    },
    actions: {
        setActiveArgument(argument, activeArgumentIndex) {
            this.activeArgument = argument;
            this.activeArgumentIndex = activeArgumentIndex;
        },
        prevArgumentAction() {
            if (!this.isFirstArgument) {
                const prevItemIndex = proposalStore().proposalArguments.findLastIndex((a, idx) => !!a.argumentDetailed && idx < this.activeArgumentIndex, this);
                if (prevItemIndex !== -1) {
                    this.setActiveArgument(proposalStore().proposalArguments[prevItemIndex], prevItemIndex);
                }
            }
        },
        nextArgumentAction() {
            if (!this.isLastArgument) {
                const nextItemIndex = proposalStore().proposalArguments.findIndex((a, idx) => !!a.argumentDetailed && idx > this.activeArgumentIndex, this);
                if (nextItemIndex !== -1) {
                    this.setActiveArgument(proposalStore().proposalArguments[nextItemIndex], nextItemIndex);
                }
            }
        },
        getArguments(proposalId) {
            return axios.get(`/api/arguments?page=0&proposalIds=${proposalId}&size=100`);
        },
        createNewArgument(data) {
            return axios.post(`/api/arguments`, data);
        },
        updateArgument(argId, data) {
            return axios.put(`/api/arguments/${argId}`, data);
        },
        setArgumentContent(argumentId, contentId) {
            return axios.put(`/api/arguments/${argumentId}/detailed?contentId=${contentId}` );
        },
        rateArgumentAction(argumentId, ratingType ) {
            return axios.put(`/api/argument/ratings?argumentId=${argumentId}&ratingType=${ratingType}`)
                .then((resp)=> {
                    this.currentArgumentRating = resp.data;
                });
        },
        getArgumentAllRatings(argId) {
            return axios.get(`/api/argument/ratings?apiRequest=ALL&argumentIds=${argId}&page=0&size=100`)
                .then((resp)=> {
                    this.argRatings = resp.data.content;
                });
        },
        getCurrentArgumentRatings(argId) {
            return axios.get(`/api/argument/ratings/top?argumentId=${argId}`)
                .then((resp)=> {
                    this.currentArgumentRating = resp.data;
                }).catch(function (error) {
                    console.log(error);
                });
        },
        getArgumentCount(argId) {
            return axios.get(`/api/argument/ratings/counts?argumentId=${argId}`)
                .then((resp)=> {
                    this.argRatingCount = resp.data;
                });
        },
    }
})