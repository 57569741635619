<script>
import {mapActions, mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";
import {argumentStore} from "@/store/argumentStore";
import ProposalArgumentVideoPlayer from "@/views/proposal/proposal-argument/ProposalArgumentVideoPlayer.vue";
import ProposalArgumentTextPlayer from "@/views/proposal/proposal-argument/ProposalArgumentTextPlayer.vue";
import ProposalArgumentPlayerControls from "@/views/proposal/proposal-argument/ProposalArgumentPlayerControls.vue";

export default {
  name: "ProposalArgument",
  props: {
    argId: {
      type: Number
    },
    proposalId: {
      type: String
    }
  },
  data() {
    return {

    }
  },
  components: {
    ProposalArgumentVideoPlayer,
    ProposalArgumentTextPlayer,
    ProposalArgumentPlayerControls
  },
  watch: {
    activeArgument: {
      handler(val) {
        console.log(val);
      }
    }
  },
  computed: {
    ...mapState(argumentStore, [
        'activeArgument',
        'currentArgumentRating'
    ]),
    ...mapState(proposalStore, [
        'proposalArguments'
    ]),
    argType() {
      return this.activeArgument.argumentDetailed ? this.activeArgument.argumentDetailed.type : null;
    },
    argContent() {
      return this.activeArgument.argumentDetailed;
    }
  },
  methods: {
    ...mapActions(argumentStore, [
        'getCurrentArgumentRatings',
        'getArgumentCount'
    ])
  },
}
</script>

<template>
<div class="argument-section">
  <div v-if="activeArgument" class="argument-player-wrap">
    <proposal-argument-video-player v-if="argType === 'VIDEO'"
                                    :content="argContent"
    />
    <proposal-argument-text-player v-else-if="argType === 'HTML_TEXT'"
                                   :content="argContent"
    />
    <proposal-argument-player-controls/>
  </div>
  <div class="argument-sources-wrap"></div>
</div>
</template>

<style lang="scss" scoped>
.argument-section {
  display: flex;
  height: 510px;
  margin-bottom: 10px;
  background: #fff;

  .argument-player-wrap {
    width: 637px;
  }

  .argument-sources-wrap {
    padding-top: 10px;
  }
}

</style>