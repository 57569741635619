<script>
import {mapActions, mapState} from "pinia";
import {opinionStore} from "@/store/opinionStore";
import Dialog from "primevue/dialog";
import BaseIframe from "@/components/BaseIframe.vue";

export default {
  name: "AddArgumentsModal",
  components: {
    Dialog,
    BaseIframe
  },
  props: {
    proposalId: {
      type: Number
    },
    opinionLike: {
      type: String
    }
  },
  computed: {
    ...mapState(opinionStore, ['addArgumentsModalState']),
    modalState: {
      get() {
        return this.addArgumentsModalState;
      },
      set() {
        this.toggleAddArgumentsModalState();
      },
    },
  },
  methods: {
    ...mapActions(opinionStore, ['toggleAddArgumentsModalState']),
  },
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="add-argument-modal"
          modal
          :header="$t('opinion.enterOpinion')"
  >
    <Base-iframe
        :src="`/opinion/get/create/form?opinionLike=${opinionLike}&proposalId=${proposalId}`"
        :use-sandbox="false"
    />
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="toggleAddArgumentsModalState()">
        {{$t('common.buttons.close')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.add-argument-modal {
  min-width: 925px;

  .p-dialog-content {
    padding: 0;
  }
  .p-dialog-close-button {
    :focus-visible {
      outline: transparent !important;
    }
  }
  iframe {
    height: calc(100vh - -10px);
  }
}

</style>