<script>
import {reportStore} from "@/store/reportStore";
import Dialog from "primevue/dialog";
import {mapActions, mapState} from "pinia";

export default {
  name: "ReportContentModal",
  components: {
    Dialog
  },
  data() {
    return {
      contentUrl: '',
      issueDescription: '',
      contentUrlValidationError: false,
      issueDescriptionValidationError: false,
    };
  },
  computed: {
    ...mapState(reportStore, ['reportModalState', 'reportSentState']),
    modalState: {
      get() {
        return this.reportModalState;
      },
      set() {
        this.closeModal();
      },
    },
  },
  methods: {
    ...mapActions(reportStore, ['toggleReportModal', 'sendReportAction', 'resetReportSentState']),
    closeModal() {
      this.toggleReportModal();
      this.resetReportSentState();
    },
    resetValidationError(validationField) {
      this[validationField] = false;
    },
    sendReport() {
      this.contentUrlValidationError = this.contentUrl.length < 1;
      this.issueDescriptionValidationError = this.issueDescription.length < 1;

      if (!this.contentUrlValidationError && !this.issueDescriptionValidationError) {
        this.sendReportAction(this.contentUrl, this.issueDescription).then(() => {
          this.contentUrl = '';
          this.issueDescription = '';
        }) ;
      }
    }
  },
  mounted() {
    this.contentUrl = window.location.href;
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="report-content-modal"
          modal
          :header="$t('report.requestForCensorship')"
  >
    <template v-if="!reportSentState">
      <div class="form-group">
        <span class="form-label">
          {{$t('report.urlToSuspicious')}}
        </span>
        <div class="form-control-wrap">
          <input type="text"
                 v-model="contentUrl"
                 :class="{'form-control--error': contentUrlValidationError}"
                 @focus="resetValidationError('contentUrlValidationError')"
                 class="form-control"
          >
          <span v-if="contentUrlValidationError"
                class="form-control-error"
          >{{$t('common.validations.thisFieldIsRequired')}}</span>
        </div>
      </div>
      <div class="form-group">
        <span class="form-label">
          {{$t('report.describeYourProblem')}}
        </span>
        <div class="form-control-wrap">
          <textarea maxlength="1500"
                    cols="30"
                    rows="10"
                    v-model="issueDescription"
                    :class="{'form-control--error': issueDescriptionValidationError}"
                    @focus="resetValidationError('issueDescriptionValidationError')"
                    class="form-control form-control--textarea"
          ></textarea>
          <span v-if="issueDescriptionValidationError"
                class="form-control-error"
          >{{$t('common.validations.thisFieldIsRequired')}}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="report-send-content">
        <span class="report-send__title">
          {{$t('report.reportSent.thanksForYourReport')}}
        </span>
        <div class="report-send__img"></div>
        <span class="report-send__desc">
          {{$t('report.reportSent.yourReportIsSuccessfully')}}
        </span>
      </div>
    </template>
    <template v-if="!reportSentState" #footer>
      <button type="button" class="p-dialog-close-btn" @click="closeModal">
        {{$t('common.buttons.close')}}
      </button>
      <button type="button" class="p-dialog-primary-btn" @click="sendReport">
        {{$t('common.buttons.send')}}
      </button>
    </template>
  </Dialog>
</template>


<style lang="scss">
.report-content-modal {
  max-width: 540px;
  width: 540px;

  .p-dialog-content {
    padding: 15px 35px;
  }

  .form-group {
    display: flex;
    margin-bottom: 20px;
    justify-content: end;
    .form-label {
      margin-right: 15px;
      padding-top: 6px;
      font-size: 12px;
      font-weight: 700;
      color: #4f4f4f;
    }
    .form-control {
      min-height: 28px;
      width: 240px;
      padding: 0 8px;
      font-size: 12px;
      border: 1px solid #ccc;
      &:focus {
        outline: transparent !important;
      }
      &--textarea {
        min-width: 240px;
        height: 80px;
        max-width: 240px;
        padding: 8px 15px 10px;
      }
      &--error {
        border-color: #fe8f18;
      }
    }

    .form-control-error {
      display: block;
      margin-top: 5px;
      font-size: 12px;
      color: #fe8f18;
    }
  }

  .report-send-content {
    display: flex;
    flex-direction: column;
    .report-send {
      &__title {
        display: inline-block;
        margin: 80px auto 36px;
        width: 380px;
        font-size: 24px;
        line-height: normal;
        color: #000;
        text-align: center;
      }
      &__img {
        width: 91px;
        margin: 0 auto 36px;
        height: 88px;
        background: url("~@/assets/img/proposal/censorship-smile.svg") no-repeat 50%;
      }
      &__desc {
        display: inline-block;
        width: 380px;
        margin: 0 auto 127px;
        font-size: 18px;
        line-height: normal;
        color: #4f4f4f;
        text-align: center;
      }
    }
  }
}
</style>