<script>
import {forumSelectStore} from "@/store/forumSelectStore";
import {mapState} from "pinia";

export default {
  name: 'ForumsSelectTree',
  data() {
    return {
      treeData: {
        expanded: false,
      },
      level_1: {
        expanded: false,
      },
      level_2_lan: {
        expanded: false,
      },
      level_2_kom: {
        expanded: false,
      },
    };
  },
  computed: {
    ...mapState(forumSelectStore, [
      'forumsL1',
      'forumsL2',
      'forumsL3',
      'forumsL4',
    ]),
  },
  methods: {
    imgSrc(item) {
      return `/viewimage?sh=${item.imgHash}&type=FORUM`;
    },
    expand(id) {
      if (id === 3) {
        this.level_2_lan.expanded = !this.level_2_lan.expanded;
      }
      if (id === 4) {
        this.level_2_kom.expanded = !this.level_2_kom.expanded;
      }
    },
    onChange() {
      this.$emit('on-change');
      this.$forceUpdate();
    },
    onSelect(forum) {
      if (forum.type !== "LEVEL") {
        this.$emit('on-select', forum);
        this.$forceUpdate();
      }
    },
    onClose() {
      this.$emit('on-close');
    },
    expandTree() {
      this.level_1.expanded = !this.level_1.expanded;
    },
  }
};
</script>

<template>
  <div class="forum-tree" v-if="forumsL1.length > 0 && forumsL2.length > 0 && forumsL3.length > 0 && forumsL4.length > 0">
    <div @click.stop="level_1.expanded = !level_1.expanded"
         :class="{expanded: level_1.expanded}"
         class="tree__item level-1"
    >
      <div class="tree__item-control-icon">
        <font-awesome-icon v-if="level_1.expanded" icon="caret-down"/>
        <font-awesome-icon v-else icon="caret-right"/>
      </div>
      <div class="tree__item-img level-1"></div>
      <span class="tree__item-label">{{forumsL1[0].name}}</span>
    </div>
    <div v-if="level_1.expanded">
      <div v-for="(item2, index) in forumsL2"
           :key="index"
           @click.stop="expand(item2.id)"
      >
        <div :class="{expanded: (
                      level_2_lan.expanded && item2.id === 3 ||
                        level_2_kom.expanded && item2.id === 4
                    )}"
             class="tree__item level-2"
        >
          <div class="tree__item-control-icon">
            <font-awesome-icon
                v-if="level_2_lan.expanded && item2.id === 3 && item2.id !== 1"
                icon="caret-down"/>
            <font-awesome-icon
                v-else-if="level_2_kom.expanded && item2.id === 4 && item2.id !== 1"
                icon="caret-down"/>
            <font-awesome-icon v-else-if="item2.id !== 1" icon="caret-right"/>
          </div>
          <div v-if="item2.id === 3"
               class="tree__item-img level-2 landsting"></div>
          <div v-if="item2.id === 4"
               class="tree__item-img level-2 kommuner"></div>
          <img v-if="item2.id === 1"
               :src="imgSrc(item2)"
               alt=""
               class="tree__item-img level-2"
          >
          <span class="tree__item-label"
                @click="onSelect(item2)"
          >{{item2.name}}</span>
        </div>
        <div v-if="level_2_lan.expanded && item2.id === 3">
          <div v-for="item3 in forumsL3"
               :key="item3.id"
               @click.stop.prevent="onSelect(item3)"
               class="tree__item level-3"
          >
            <img :src="imgSrc(item3)"
                 alt=""
                 class="tree__item-img"
            >
            <span class="tree__item-label">{{item3.name}}</span>
          </div>
        </div>
        <div v-if="level_2_kom.expanded && item2.id === 4">
          <div v-for="item4 in forumsL4"
               :key="item4.id"
               @click.stop.prevent="onSelect(item4)"
               class="tree__item level-3"
          >
            <img :src="imgSrc(item4)"
                 alt=""
                 class="tree__item-img"
            >
            <span class="tree__item-label">{{item4.name}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
//@import "~@/assets/scss/variables";


.forum-tree {

}

.tree__item {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 16px 12px;
  cursor: pointer;

  &.expanded {
    background: #f1e8f8;
  }

  &.level-2 {
    padding-left: 32px;
  }

  &.level-3 {
    padding-left: 80px;
  }
}

.tree__item-control-icon {
  font-size: 15px;
  width: 6px;
  height: 15px;
  float: left;
  margin-right: 15px;
}

.tree__item-img {
  display: block;
  float: left;
  margin-right: 14px;
  width: 18px;
  height: 22px;

  &.landsting {
    background: url("~@/assets/img/header/landsting@3x.png") no-repeat;
  }

  &.kommuner {
    background: url("~@/assets/img/header/kommuner@3x.png") no-repeat;
  }

  &.level-1 {
    width: 30px;
    height: 21px;
    display: inline-block;
    background: url("~@/assets/img/header/sweden-flag.svg") no-repeat;
  }

  &.level-2 {
    width: 25px;
    height: 25px;
    background-size: cover;
  }
}

.tree__item-label {
  font-size: 12px;
  font-weight: 600;
}

.follow-btn {
  margin-left: auto;
}
</style>
