<script>
import BaseIframe from "@/components/BaseIframe.vue";
export default {
  name: "OrganizationView",
  components: {
    BaseIframe
  },
  computed: {
    frameUrl() {
      return this.$route.fullPath + '?_f=true';
    }
  }
}
</script>

<template>
  <Base-iframe
      :src="frameUrl"
  ></Base-iframe>
</template>