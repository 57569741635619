<script>
import i18n from '@/i18n';
import {mapActions} from 'pinia';
import {authStore} from '@/store/authStore';
import {proposalStore} from '@/store/proposalStore';
import BaseAvatar from '@/components/BaseAvatar/BaseAvatar.vue';
import ProposalGeneralInfoAdditional from '@/views/proposal/proposal-general-info/ProposalGeneralInfoAdditional.vue';
import {formatZonedDateTime} from '@/baseUtils';

export default {
  name: "ProposalGeneralInfo",
  props: {
    proposalInfo: {
      type: Object
    },
  },
  components: {
    BaseAvatar,
    ProposalGeneralInfoAdditional,
  },
  data() {
    return {
      additionalInfoState: false,
    }
  },
  computed: {
    proposalTitle() {
      const externalProposalType = this.proposalInfo.extraAttributes.externalProposalType;

      return externalProposalType === 'COUNTER_MOTION'
          ? `${i18n.global.t('proposal.counterProposal.label')} ${this.proposalInfo.title}` : this.proposalInfo.title;
    },
    proposalCreateDate() {
      return formatZonedDateTime(this.proposalInfo.createDateTime);
    },
    committeeName() {
      const committeeType = this.proposalInfo.extraAttributes.committeeType;
      if (committeeType === 'LABOR_MARKET') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.laborMarket');
      } else if (committeeType === 'CIVIL_AFFAIRS') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.civilAffairs');
      } else if (committeeType === 'FINANCE') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.finance');
      } else if (committeeType === 'DEFENCE') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.defence');
      } else if (committeeType === 'JUSTICE') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.justice');
      } else if (committeeType === 'CONSTITUTION') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.constitution');
      } else if (committeeType === 'CULTURE') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.culture');
      } else if (committeeType === 'ENVIRONMENT') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.environment');
      } else if (committeeType === 'INDUSTRY') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.industry');
      } else if (committeeType === 'TAXES') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.taxes');
      } else if (committeeType === 'INSURANCE') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.insurance');
      } else if (committeeType === 'HEALTH') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.health');
      } else if (committeeType === 'COMMUNICATIONS') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.communications');
      } else if (committeeType === 'EDUCATION') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.education');
      } else if (committeeType === 'FOREIGN_AFFAIRS') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.foreignAffairs');
      } else if (committeeType === 'DEFENSE') {
        return i18n.global.t('proposal.external.riksdagen.committeeName.defense');
      }
      return '';
    },
    proposalStatusText() {
      const proposalStatus = this.proposalInfo.extraAttributes.status;
      const proposalForumName = this.proposalInfo.forum.name;
      const committeeName = this.committeeName;
      if (proposalStatus === 'DRAFT') {
        return i18n.global.t('proposal.generalInfo.proposalStatus.draft');
      } else if (proposalStatus === 'TO_BE_RAISED') {
        return `${i18n.global.t('proposal.generalInfo.proposalStatus.toBeRaisedTo')} ${proposalForumName}`;
      } else if (proposalStatus === 'REVOKED') {
        return i18n.global.t('proposal.generalInfo.proposalStatus.archivedDueToRevoked');
      } else if (proposalStatus === 'NOT_APPROVED') {
        return `${i18n.global.t('proposal.generalInfo.proposalStatus.archivedDueToNotApproved')} ${proposalForumName}`;
      } else if (proposalStatus === 'EXPIRED') {
        return i18n.global.t('proposal.generalInfo.proposalStatus.archivedDueToExpired');
      } else if (proposalStatus === 'ARCHIVED') {
        return i18n.global.t('proposal.generalInfo.proposalStatus.archived');
      } else if (proposalStatus === 'REGISTERED') {
        return `${i18n.global.t('proposal.generalInfo.proposalStatus.registered')} ${proposalForumName}`;
      } else if (proposalStatus === 'APPROVED') {
        return `${i18n.global.t('proposal.generalInfo.proposalStatus.approved')} ${proposalForumName}`;
      } else if (proposalStatus === 'REFERRED' || proposalStatus === 'TO_BE_DECIDED' || proposalStatus === 'IMPLEMENTED' || proposalStatus === 'REJECTED') {
        return `${i18n.global.t('proposal.generalInfo.proposalStatus.referredTo')} ${committeeName}`;
      }
      return '';
    },
    separatorControlCaption() {
      return this.additionalInfoState
          ? i18n.global.t('proposal.generalInfo.minimize') : i18n.global.t('proposal.generalInfo.maximize');
    }
  },
  methods: {
    ...mapActions(authStore, [
        'checkUserIsApproved'
    ]),
    ...mapActions(proposalStore, [
        'toggleLifeCycleModal'
    ]),
    openForumView() {
      const forumUrl =  '/forum/' + this.proposalInfo.forum.id;
      window.open(forumUrl, '_blank')
    },
    showProposalLogbook() {
      alert('To be implemented');
    },
    followProposal() {
      if (this.checkUserIsApproved()) {
        alert('To be implemented');
      }
    },
    switchAdditionalInfo() {
      this.additionalInfoState = !this.additionalInfoState;
    },
  }
}
</script>

<template>
<div class="proposal-general-info">
  <div class="forum-flag" @click="openForumView">
    <div class="forum-flag__icon riksdagen-icon"></div>
    {{proposalInfo.forum ? proposalInfo.forum.name : ''}}
  </div>
  <div class="proposal-status-flag">
    <i class="proposal-status-flag__icon"></i>
    <div class="proposal-status-flag__caption">
      {{ proposalStatusText }}
    </div>
  </div>
  <div class="proposal-general-info__main-info">
    <router-link :to="{name: 'UserView', params: {id: proposalInfo.proposer.id}}"
                 target="_blank"
    >
      <BaseAvatar
          :img-hash='proposalInfo.proposer.imgHash'
          :size="'XL'"
          class="proposer-avatar"
          v-tooltip.bottom="proposalInfo.proposer.name"
      />
    </router-link>

    <div class="proposal-info-container">
      <h1 class="proposal-name">{{proposalTitle}}</h1>
      <div class="info-items-list">
        <div class="info-item">
          <span class="info-item__label">
            {{$t('proposal.generalInfo.created')}}
          </span>
          <span class="info-item__link">
            {{proposalCreateDate}}
          </span>
          <i class="logbook-icon"
             @click="showProposalLogbook"
          ></i>
        </div>
        <font-awesome-icon :icon="['fas', 'circle']"
                           class="info-item-separator"
        >
        </font-awesome-icon>
        <div class="info-item">
          <span class="info-item__label">
            {{$t('proposal.generalInfo.issueNumber')}}
          </span>
          <router-link :to="{name: 'ProposalView', params: {id: proposalInfo.issueNumber}}"
                       class="info-item__link"
          > #{{proposalInfo.issueNumber}}</router-link>
        </div>
      </div>
    </div>
<!--    <button type="button"-->
<!--            @click="followProposal"-->
<!--            class="follow-btn"-->
<!--    >-->
<!--      <i class="follow-btn__icon"></i>-->
<!--      <span class="follow-btn__label">Follow</span>-->
<!--    </button>-->
  </div>

  <div class="proposal-header-separator">
    <div class="proposal-header-separator__line"></div>
    <div class="proposal-header-separator__control separator-control" @click="switchAdditionalInfo">
      <div class="separator-control__caption">
        {{separatorControlCaption}}
      </div>
      <font-awesome-icon v-if="additionalInfoState"
                         :icon="['fas', 'angle-up']"
                         class="separator-control__icon"
      ></font-awesome-icon>
      <font-awesome-icon v-else
                         :icon="['fas', 'angle-down']"
                         class="separator-control__icon"
      ></font-awesome-icon>
    </div>
  </div>

  <transition name="slidedown">
    <proposal-general-info-additional
        v-show="additionalInfoState"
        :info="proposalInfo"
    ></proposal-general-info-additional>
  </Transition>
</div>
</template>

<style lang="scss" scoped>
.proposal-general-info {
  width: 100%;
  min-width: 970px;
  position: relative;
  margin-bottom: 10px;
  background: #ffffff;

  .forum-flag {
    display: flex;
    align-items: center;
    position: absolute;
    height: 24px;
    top: 10px;
    width: fit-content;
    padding: 5px 24px 5px 6px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #ffffff;
    background: #7835a6;
    cursor: pointer;

    &:after {
      position: absolute;
      top: 0;
      right: -1px;
      content: '';
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #fff;
    }

    &__icon {
      width: 16px;
      height: 20px;
      margin-right: 9px;

      &.riksdagen-icon {
        width: 22px;
        height: 15px;
        background: url("~@/assets/img/proposal/riksdagen-icon.svg"), no-repeat, center;
      }
    }
  }

  .proposal-status-flag {
    display: flex;
    align-items: center;
    position: absolute;
    padding: 0 10px 0 5px;
    top: 10px;
    right: 0;
    height: 24px;
    background: #5586ff;
    text-transform: uppercase;

    &:after {
      position: absolute;
      top: 0;
      left: -12px;
      content: '';
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #5586ff
    }

    &__icon {
      display: block;
      height: 18px;
      width: 18px;
      margin-right: 5px;
      background: url("~@/assets/img/proposal/status-icon.svg"), no-repeat, center;
    }

    &__caption {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.43;
      color: #fff;
    }
  }

  &__main-info {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    padding: 45px 8px 0 15px;

    .proposer-avatar {
      margin-right: 10px;
    }
  }

  .proposal-header-separator {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 0 0 5px 15px;
    
    &__line {
      width: 100%;
      margin: 0 10px 0 0;
      height: 1px;
      background: #f2f2f2;
    }
    &__control {
      display: flex;
      align-items: center;
      margin-right: 15px;
      cursor: pointer;
    }
    
    .separator-control {
      &__caption {
        margin-right: 5px;
        font-size: 10px;
        color: #7835a6;
      }
      &__icon {
        position: relative;
        top: 1px;
        font-size: 10px;
        color: #7835a6;
      }
    }
  }

  .proposal-info-container {
    overflow: hidden;
    max-width: 680px;
    font-family: 'Open Sans', Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-transform: none;
    margin: 1px 0 8px;
    color: #4f4f4f;

    .proposal-name {
      overflow: hidden;
      width: 680px;
      font-family: 'Open Sans', Arial, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      text-transform: none;
      margin: 1px 0 8px;
      color: #4f4f4f;
    }

    .info-items-list {
      display: flex;
      align-items: center;
    }

    .info-item {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;

      &__label {
        margin-right: 5px;
      }
      &__text {

      }
      &__link {
        color: #7835a6;
        cursor: pointer;
        text-decoration: none;
      }
    }

    .info-item-separator {
      margin: 0 5px;
      font-size: 3px;
    }

    .logbook-icon {
      width: 15px;
      height: 15px;
      margin-left: 5px;
      background: url("~@/assets/img/proposal/logbook.svg") no-repeat 50%;
      background-size: 100%;
      cursor: pointer;
    }
  }

  .follow-btn {
    height: 41px;
    width: 113px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    background: #18bafe;
    border: 2px solid #18bafe;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: #289de7;
      border-color: #289de7;
    }

    &:active {
      background: #18bafe;
      border-color: #289de7;
      box-shadow: 0 0 5px #289de7 inset;
    }

    &__icon {
      display: block;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      background: url("~@/assets/img/proposal/icon_star.svg") no-repeat 50%;
    }

    &__label {
      font-size: 14px;
      font-family: 'Open Sans', Arial, sans-serif;
      font-weight: 600;
      line-height: 37px;
    }
  }
}



</style>