<script>
export default {
  name: "BaseLoader",
  props: {
    state: {
      type: Boolean
    }
  },
}
</script>

<template>
  <div v-if="state" class="base-loader">
    <div class="base-loader__overlay"></div>
    <div class="base-loader__ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.base-loader {
  position: fixed;
  z-index: 1102;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &__overlay {
     position: fixed;
     z-index: 100;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
     background: linear-gradient(to bottom, rgba(218, 218, 218, 0.8), rgba(218, 218, 218, 1));
     -webkit-overflow-scrolling: touch;
   }


  .base-loader__ring {
    position: absolute;
    z-index: 100;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 80px;
    height: 80px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid #fff;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #7835a6 transparent transparent transparent;
    }
  }

  .base-loader__ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .base-loader__ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .base-loader__ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>