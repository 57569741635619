<script>
export default {
  name: "ProposalCounterSection",
  props: {
    reviewedProposal: {
      type: Boolean
    },
    proposalInfo: {
      type: Object
    }
  }
}
</script>

<template>
  <div v-if="reviewedProposal" class="counter-proposal-section counter-proposal-section--deny">
    <div class="counter-proposal-section__header">
      <span class="header-caption">
        {{$t('proposal.counterProposal.countedDeny.caption')}}
      </span>
      <div class="header-img"></div>
    </div>
    <div class="counter-proposal-section__content">
      <span class="title">
        {{$t('proposal.counterProposal.countedDeny.title')}}
      </span>
      <span class="text mb_25">
        {{$t('proposal.counterProposal.countedDeny.p1')}}
        <a :href="`/proposal/${proposalInfo.issueNumber}`"
           class="link">"{{ proposalInfo.proposalTitle}}"</a>.
      </span>
      <span class="text mb_25">
        {{$t('proposal.counterProposal.countedDeny.p2')}}
      </span>
      <span class="text mb_25">
        {{$t('proposal.counterProposal.countedDeny.p3')}}
      </span>
      <a href="https://www.riksdagen.se/sv/dokument-lagar/dokument/svensk-forfattningssamling/riksdagsordning-2014801_sfs-2014-801%22"
         target="_blank"
         class="link"
      >
        <i class="fas fa-external-link-square-alt mr_5"></i>
        {{$t('proposal.counterProposal.countedDeny.readAboutLink')}}
      </a>
    </div>
  </div>
  <div v-else class="counter-proposal-section">
    <div class="counter-proposal-section__header">
      <div class="header-img">
        <svg version="1.1" class="counter-proposal-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 461.295 461.295"
             style="enable-background:new 0 0 461.295 461.295;"
             xml:space="preserve">
          <g>
            <path d="M432.438,435.688L366.39,369.64c34.533-44.958,31.232-109.829-9.913-150.974c-11.978-11.978-26.139-20.974-41.583-26.633
                                                   V88.33c0-3.979-1.58-7.794-4.394-10.606l-73.33-73.33C234.358,1.58,230.542,0,226.564,0H39.463c-8.284,0-15,6.716-15,15v319.199
                                                    c0,8.284,6.716,15,15,15h132.31c5.465,11.485,12.936,22.246,22.431,31.74c21.672,21.673,50.486,33.608,81.137,33.608
                                                    c25.591,0,49.899-8.327,69.843-23.689l66.042,66.043c2.929,2.93,6.768,4.394,10.606,4.394c3.838,0,7.678-1.465,10.606-4.394
                                                    C438.296,451.044,438.296,441.546,432.438,435.688z M241.562,51.211l22.121,22.121h-22.121V51.211z M54.463,319.199V30h157.099
                                                    v58.332c0,8.284,6.716,15,15,15h58.332v82.123c-3.161-0.259-6.346-0.397-9.554-0.397c-8.109,0-16.087,0.844-23.84,2.474H80.433
                                                    c-5.522,0-10,4.478-10,10s4.478,10,10,10h126.66c-4.524,3.361-8.832,7.077-12.889,11.135c-5.262,5.262-9.897,10.916-13.921,16.863
                                                    H80.433c-5.522,0-10,4.478-10,10s4.478,10,10,10h89.055c-8.422,20.21-10.826,42.356-7.2,63.67H54.463z M193.483,278.028h64.151
                                                    c5.522,0,10-4.478,10-10s-4.478-10-10-10h-56.01c3.679-6.498,8.262-12.619,13.793-18.149
                                                    c16.006-16.006,37.287-24.821,59.924-24.821c11.921,0,23.457,2.468,34.054,7.121V345.02H203.684
                                                    C190.957,324.853,187.556,300.461,193.483,278.028z M221.231,365.02h98.163c5.522,0,10-4.478,10-10V234.538
                                                    c2.024,1.682,3.986,3.457,5.87,5.341c33.042,33.042,33.042,86.806,0,119.848c-16.007,16.006-37.288,24.821-59.924,24.821
                                                    C255.343,384.548,236.41,377.658,221.231,365.02z"/>
            <path d="M80.433,159.534h178.491c5.522,0,10-4.478,10-10s-4.478-10-10-10H80.433c-5.522,0-10,4.478-10,10S74.911,159.534,80.433,159.534z"/>
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
      </div>
    </div>
    <div class="counter-proposal-section__content">
      <span class="title">
        {{$t('proposal.counterProposal.title')}}
      </span>
      <span class="text mb_25">
        {{$t('proposal.counterProposal.p1')}}
      </span>
      <span class="text mb_25">
        {{$t('proposal.counterProposal.p2')}}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.counter-proposal-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-right: 10px;
  padding: 20px 64px 40px;
  background: #fff;
  box-sizing: border-box;

  &__header {
    margin-bottom: 35px;
    .header-caption {
      display: inline-block;
      max-width: 195px;
      margin-bottom: 5px;
      font-size: 12px;
      font-weight: 600;
      color: #7b7b7b;
    }
    .header-img {
      width: 160px;
      height: 132px;
      margin: 10px 0 40px;

      .counter-proposal-icon {
        fill: #7835a6;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      display: inline-block;
      margin-bottom: 25px;
      font-size: 17px;
      font-weight: 600;
      text-align: center;
    }
    .text {
      display: inline-block;
      margin-bottom: 25px;
      font-size: 15px;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: -0.01px;
      color: #3c3c3c;
    }
  }

  &--deny {
    .header-img {
      width: 160px;
      height: 132px;
      margin-bottom: 0;
      background: url("~@/assets/img/proposal/counter-proposal-type-deny.svg") no-repeat;
    }

    .link {
      font-weight: 600;
      color: #7835a6;
      text-decoration: none;
    }
  }
}
</style>