<script>
import {mapState, mapActions} from "pinia";
import {mainSearchStore} from "@/store/mainSearchStore";
import Dialog from "primevue/dialog";
export default {
  name: "MainSearchResultModal",
  components: {
    Dialog
  },
  computed: {
    ...mapState(mainSearchStore, [
      'searchResultInfoModalState'
    ]),
    modalState: {
      get() {
        return this.searchResultInfoModalState;
      },
      set() {
        this.closeModal();
      },
    },
  },
  methods: {
    ...mapActions(mainSearchStore, [
        'closeSearchResultInfoModal'
    ]),
    closeModal() {
      this.closeSearchResultInfoModal();
    }
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="search-result-info-modal"
          modal
          :header="$t('header.search.searchIncorrectResultTitle')"
  >
    <div>
      {{ $t('header.search.searchIncorrectResult') }}
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="closeModal()">
        {{$t('common.buttons.close')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.search-result-info-modal {
  .p-dialog-content {
    padding: 20px;
  }
}
</style>