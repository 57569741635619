<script>
export default {
  name: "ProposalNoArguments"
}
</script>

<template>
  <div class="no-argument-wrap">
    <div class="no-argument">
      <div class="no-argument__img"></div>
      <div class="no-argument__caption">
        {{$t('proposal.haveNoArguments')}}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.no-argument-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 297px;
  width: 100%;
  margin-bottom: 10px;
  box-shadow: 0 2px 9px 0 rgba(79, 79, 79, .15);
  background-image: linear-gradient(to bottom, #eaeaea, #d1d1d1);

  .no-argument {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__img {
      width: 103px;
      height: 92px;
      margin-bottom: 30px;
      background: url("~@/assets/img/proposal/no-argments.svg"), no-repeat, center;
    }
    &__caption {
      display: inline-block;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.28;
      letter-spacing: -.01px;
      color: #3c3c3c;
    }
  }
}
</style>