<script>
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import Dialog from "primevue/dialog";


export default {
  name: "PleaseVerifyUserModal",
  components: {
    Dialog
  },
  computed: {
    ...mapState(authStore, [
        'needVerifyUserModalState'
    ]),
    modalState: {
      get() {
        return this.needVerifyUserModalState;
      },
      set() {
        this.toggleNeedVerifyUserModal();
      },
    },
  },
  methods: {
    ...mapActions(authStore, [
        'toggleNeedVerifyUserModal'
    ]),
    closeModal() {
      this.toggleNeedVerifyUserModal();
    }
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="need-approve-user-modal"
          modal
          :header="$t('auth.needApproveUserModal.title')"
  >
    <div>
      {{$t('auth.needApproveUserModal.p1')}}<br>
      {{$t('auth.needApproveUserModal.p2')}}
      <a href="mailto:gustaf@cavallicompany.com">gustaf@cavallicompany.com.</a>
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn"
              @click="closeModal"
      >
        {{$t('common.buttons.cancel')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.need-approve-user-modal {
  .p-dialog-content {
    max-width: 500px;
    font-size: 12px;
    line-height: 16px;
  }
}
</style>