<script>
import {mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";
import BaseTab from "@/components/BaseTab.vue";
import BaseProposalListItem from "@/components/BaseProposalListItem.vue";
import BaseNoResultMessage from "@/components/BaseNoResultMessage.vue";

export default {
  name: "ProposalCreateSearchResult",
  components: {
    BaseTab,
    BaseProposalListItem,
    BaseNoResultMessage
  },
  data() {
    return {
      tabs: [
        {
          code: 'CURRENT',
          label: 'New'
        },
        {
          code: 'DRAFT',
          label: 'Drafts'
        },
      ],
      activeTab: null
    }
  },
  computed: {
    ...mapState(proposalStore, [
      'existingProposals'
    ]),
    currentProposals() {
      return this.existingProposals.filter((proposal)=> {
        return proposal.status === 'CURRENT'
      });
    },
    draftProposals() {
      return this.existingProposals.filter((proposal)=> {
        return proposal.status === 'DRAFT'
      });
    }
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    }
  },
  beforeMount() {
    this.setActiveTab(this.tabs[0]);
  }
}
</script>

<template>
<div class="existing-proposals-wrap">
  <div class="proposal-types-tabs">
    <base-tab v-for="tab in tabs"
              :key="tab.code"
              :label="tab.label"
              :active="tab.code === activeTab.code"
              @click="setActiveTab(tab)"
    />
  </div>
  <div class="existing-proposals-list">
    <template v-if="activeTab.code === 'CURRENT'">
      <template v-if="currentProposals.length > 0">
        <base-proposal-list-item v-for="item in currentProposals"
                                 :key="item.id"
                                 :item-data="item"
                                 :highlight-title="'test'"
        />
      </template>
      <template v-else>
        <base-no-result-message
            :text="'No results'"
        />
      </template>
    </template>
    <template v-else-if="activeTab.code === 'DRAFT'">
      <template v-if="draftProposals.length > 0">
        <base-proposal-list-item v-for="item in draftProposals"
                                 :key="item.id"
                                 :item-data="item"
                                 :highlight-title="'test'"
        />
      </template>
      <template v-else>
        <base-no-result-message
            :text="'No results'"
        />
      </template>
    </template>
  </div>
</div>
</template>

<style lang="scss" scoped>
.existing-proposals-wrap {
  .proposal-types-tabs {
    display: flex;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px 0 rgba(79, 79, 79, 0.15);
  }

 .existing-proposals-list {

 }

}
</style>