<script>
import Dialog from "primevue/dialog";
import {mapActions} from "pinia";
import {mainSearchStore} from "@/store/mainSearchStore";
export default {
  name: "MainSearch",
  components: {
    Dialog
  },
  data() {
    return {
      searchString: "",
      focusedState: false,
      minCharErrorModalState: false
    }
  },
  computed: {
    disabledState() {
      return this.searchString.length < 3;
    }
  },
  methods: {
    ...mapActions(mainSearchStore, [
        'openSearchResultInfoModal'
    ]),
    runSearch() {
      if (this.disabledState) {
        this.minCharErrorModalState = true;
      } else {
        this.openSearchResultInfoModal(()=> {
          window.location.href = `/search/result?q=${this.searchString}&type=ALL`;
        });
      }
    }
  },
  created() {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Enter' && this.focusedState && this.searchString.length >= 3) {
        this.runSearch();
      }
    });
  },
}
</script>

<template>
  <div class="main-search">
    <input type="text"
           v-model="searchString"
           :placeholder="$t('header.search.searchPlaceholder')"
           class="main-search__field"
           @focus="focusedState = true"
           @blur="focusedState = false"
    >
    <button class="main-search__button"
            :class="{'disabled': disabledState}"
            @click="runSearch"

    >
      <font-awesome-icon :icon="['fas', 'search']"
      ></font-awesome-icon>
    </button>
  </div>
  <Dialog v-model:visible="minCharErrorModalState"
          modal
          class="min-char-error-modal"
          :header="'Message'"
  >
    <p class="min-char-error-modal__text">
      {{$t('header.search.youMustEntered')}}
    </p>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="minCharErrorModalState = false">
        {{$t('common.buttons.close')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss" scoped>
.main-search {
  display: flex;
  &__field {
    display: flex;
    align-items: center;
    width: 368px;
    height: 28px;
    padding: 0 9px;
    color: #4f4f4f;
    border-radius: 3px 0 0 3px;
    font-size: 12px;
    border: 0;
    outline: none;
  }
  &__button {
    width: 52px;
    height: 28px;
    border-radius: 0 3px 3px 0;
    display: inline-block;
    background-color: #17bafd;
    border: none;
    outline: none;
    color: #ffffff;
    padding: 0;
    vertical-align: top;
    cursor: pointer;
    font-size: 16px;
    text-align: center;

    &.disabled {
      background: #cccccc;
      cursor: default;
    }
  }
}

.min-char-error-modal {
  &__text {
    padding: 20px 30px;
    font-size: 12px;
  }
}


</style>