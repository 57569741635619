<script>
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import {notificationStore} from "@/store/notificationStore";
import BaseHeaderMessage from "@/components/BaseHeaderMessage.vue";
import MainSearch from "@/components/MainSearch/MainSearch.vue";
import BaseUserMenu from "@/components/BaseUserMenu.vue";
import CreateOrgModal from "@/components/Authorization/CreateOrgModal.vue";
import ReportContentModal from "@/components/Modals/ReportContentModal.vue";
import RegisterPartyInfoModal from "@/components/Authorization/RegisterPartyInfoModal.vue";


export default {
  name: "BaseHeader",
  components: {
    BaseHeaderMessage,
    MainSearch,
    BaseUserMenu,
    CreateOrgModal,
    ReportContentModal,
    RegisterPartyInfoModal,
  },
  computed: {
    ...mapState(authStore, [
      'userInfo',
      'hasHeaderMessage',
      'userActivated',
      'showCookiePolicyMessage',
      'loginModalState',
      'managerProfileInfo',
    ]),
    ...mapState(notificationStore, ['newsEventsCount', 'criticalEventsCount']),
    isManager() {
      return this.managerProfileInfo;
    },
    headerClass() {
      let headerClass = '';

      if (this.isManager) {
        headerClass += 'header-wrap--manager '
      }

      if (!this.userActivated) {
        headerClass += 'header-wrap--not-approved-user '
      } else if (this.showCookiePolicyMessage) {
        headerClass += 'header-wrap--not-accepted-cookie '
      }

      return headerClass;
    },
    isAuthorized() {
      return this.userInfo !== null;
    },
    criticalEventsLink() {
      return this.userInfo ? `/${this.userInfo.id}/mydesktop` : '';
    }
  },
  methods: {
    ...mapActions(authStore, [
        'openSignUpModal',
        'toggleLoginModalState',
    ]),
    toggle(event) {
      this.$refs.menu.toggle(event);
    }
  }
}
</script>

<template>
<div class="header-wrap"
     :class="headerClass"
>
  <base-header-message v-if="hasHeaderMessage"/>
  <div class="header-content">
    <template v-if="isManager">
      <RouterLink to="/managerLounge" class="logo">
        <img src="../assets/img/pp-restricted-page-logo@3x.png" alt="PeoplePolitics"
             width="161"
             height="40"
        >
      </RouterLink>
    </template>
    <template v-else>
      <RouterLink to="/" class="logo">
        <img src="../assets/img/logo-beta.svg" alt="PeoplePolitics"
             width="42"
             height="42"
        >
      </RouterLink>
    </template>
    <main-search></main-search>
    <ul class="main-navigation">
      <li class="main-navigation-item main-navigation-item--news">
        <RouterLink to="/notifications"
                    class="main-navigation-item__link"
        >
          <div class="main-navigation-item__icon-wrap">
            <font-awesome-icon :icon="['fas', 'bell']"
                               class="main-navigation-item__icon"
            >
            </font-awesome-icon>
            <span v-if="newsEventsCount" class="notifications-count">{{ newsEventsCount }}</span>
          </div>
          <span class="main-navigation-item__text">
            {{$t('header.notifs')}}
          </span>
        </RouterLink>
      </li>
      <li class="main-navigation-item main-navigation-item--events">
        <RouterLink :to="criticalEventsLink"
                    class="main-navigation-item__link"
        >
          <i class="main-navigation-item__icon">
            <span v-if="criticalEventsCount" class="events-count">{{ criticalEventsCount }}</span>
          </i>
          <span class="main-navigation-item__text">
            {{$t('header.criticalEvents')}}
          </span>
        </RouterLink>
      </li>
      <template v-if="isManager">
        <li class="main-navigation-item main-navigation-item--news">
          <RouterLink to="/"
                      class="main-navigation-item__link"
          >
            <font-awesome-icon :icon="['fas', 'newspaper']"
                               class="main-navigation-item__icon"
            ></font-awesome-icon>
            <span class="main-navigation-item__text">
              {{$t('header.news')}}
            </span>
          </RouterLink>
        </li>
      </template>
    </ul>
    <template v-if="isAuthorized">
      <div class="user-menu-wrap">
        <base-user-menu></base-user-menu>
      </div>
    </template>
    <template v-else>
      <div class="login-box">
        <button type="button" class="login-box-btn login-box-btn--become"
                @click="openSignUpModal()"
        >
          {{$t('header.becomeMember')}}
        </button>
        <button type="button" class="login-box-btn login-box-btn--login"
                @click="toggleLoginModalState"
        >
          {{$t('header.login')}}
        </button>
      </div>
    </template>
  </div>
</div>
  <create-org-modal/>
  <report-content-modal/>
  <register-party-Info-modal/>
</template>

<style lang="scss">
.header-wrap {
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 70px;
  display: flex;
  background: #7835a6;

  .header-content {
    width: 970px;
    display: flex;
    align-items: center;
    margin: auto;
    padding-left: 5px;
    padding-right: 5px;

    .logo {
      margin-right: 32px;
    }

    .main-navigation {
      display: flex;
      margin-left: 25px;

      .main-navigation-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 70px;
        margin-right: 5px;
        padding-top: 5px;
        cursor: pointer;

        &:hover {
          padding-top: 0;
          background: #43235a;

          .main-navigation-item__text {
            display: block;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &__link {
          display: flex;
          align-items: center;
          flex-direction: column;
          position: relative;
          text-decoration: none;
          text-align: center;
        }
        &__icon {
          position: relative;
          margin-bottom: 5px;
          font-size: 24px;
          color: #a163cc;
        }
        &__text {
          display: none;
          font-size: 11px;
          line-height: 13px;
          color: #a163cc;
        }

        &--news {
          .main-navigation-item__icon-wrap {
            position: relative;
            .notifications-count {
              position: absolute;
              right: -12px;
              top: -6px;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 18px;
              min-width: 18px;
              border-radius: 9px;
              -webkit-border-radius: 9px 9px 9px 9px;
              -moz-border-radius: 9px;
              background: #18bafe;
              color: #fff;
              text-align: center;
              font-size: 11px;
            }
          }
        }

        &--events {
          .main-navigation-item__icon {
            width: 15px;
            height: 25px;
            background: url("~@/assets/img/header/icn_critical_events.svg") no-repeat;
            background-position: -20px -20px;
          }

          .events-count {
            position: absolute;
            right: -12px;
            top: -5px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 18px;
            min-width: 18px;
            border-radius: 9px;
            -webkit-border-radius: 9px 9px 9px 9px;
            -moz-border-radius: 9px;
            background: #fe8f18;
            color: #fff;
            font-size: 11px;
          }
        }
      }
    }

    .login-box {
      display: flex;
      margin-left: auto;

      .login-box-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: 'Open Sans', Arial, sans-serif;
        color: #ffffff;
        border-radius: 2px;
        background: none;
        border: none;
        cursor: pointer;

        &--become {
          width: 165px;
          height: 36px;
          margin-right: 5px;
          font-size: 14px;
          background: #fe8f18;
          text-transform: uppercase;
          &:hover {
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.35) inset;
          }
          &:active {
            box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.35) inset;
          }
        }
        &--login {
          height: 36px;
          max-width: 84px;
          background: transparent;
          &:hover {
            background: #672992;
          }
          &:active {
            background: #a163cc;
          }
        }
      }
    }

    .user-menu-wrap {
      margin-left: auto;
      margin-right: -5px;

      .p-menubar {
        background: none;
        border: none;
      }

      .p-menubar-submenu {
        right: 100% !important;
        left: unset !important;
      }
    }
  }

  &--not-approved-user {
    top: 45px;
  }

  &--not-accepted-cookie {
    top: 80px;
  }

  &--manager {
    background: #8e8a91;

    .header-content {
      .main-search {
        max-width: 300px;
      }
      .main-navigation {
        .main-navigation-item--events {
          .main-navigation-item__icon {
            background-position: -20px -85px;
          }
          &:hover {
            .main-navigation-item__icon {
              background-position: -20px -20px;
            }
          }
        }

        .main-navigation-item--news {
          .main-navigation-item__icon {
            color: #ffffff
          }
          &:hover {
            .main-navigation-item__icon {
              color: #a163cc;
            }
          }
        }
      }
    }
  }
}
</style>