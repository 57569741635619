<script>
export default {
  name: "BaseTab",
  props: {
    label: {
      type: String
    },
    active: {
      type: Boolean
    }
  }
}
</script>

<template>
<div class="base-tab"
     :class="{'base-tab--active': active}"
>
  <span class="base-tab__label">{{label}}</span>
</div>
</template>

<style lang="scss" scoped>
.base-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 100%;
  border-bottom: solid 2px transparent;
  background: #fff;
  cursor: pointer;
  &:hover {
    background: #f0f0f0;
  }
  &__label {
    color: #7835a6;
    font-weight: 600;
  }

  &--active {
    border-color: #fe8f18;
  }
}
</style>