<script>
import Dialog from "primevue/dialog";
import BaseIframe from "@/components/BaseIframe.vue";
import {mapActions, mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";
export default {
  name: "ProposalLifeCircleModal",
  components: {
    Dialog,
    BaseIframe
  },
  props: {
    proposalId: {
      type: Number
    }
  },
  computed: {
    ...mapState(proposalStore, ['lifeCycleModalState']),
    modalState: {
      get() {
        return this.lifeCycleModalState;
      },
      set() {
        this.toggleLifeCycleModal();
      },
    },
  },
  methods: {
    ...mapActions(proposalStore, ['toggleLifeCycleModal']),
  },
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="life-circle-modal"
          modal
          :header="$t('proposal.generalInfo.lifecycle')"
  >
    <Base-iframe
        :src="`/riksdagen/proposal/${proposalId}/lifecycle`"
        :use-sandbox="false"
    />

    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="toggleLifeCycleModal()">
        {{$t('common.buttons.close')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.life-circle-modal {
  min-width: 990px;

  .p-dialog-content {
    padding: 0;
  }
  .p-dialog-close-button {
    :focus-visible {
      outline: transparent !important;
    }
  }
  iframe {
    height: calc(100vh - -10px);
  }
}
</style>