import { createI18n } from "vue-i18n";
import en from '@/i18n/locales/en.json';
import se from '@/i18n/locales/se.json';


const messages = Object.assign({ en, se });

export default createI18n({
  legacy: false,
  locale: 'en',
  globalInjection: true,
  fallbackLocale: 'en',
  messages
});
