<script>
import Dialog from "primevue/dialog";
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import {useVuelidate} from "@vuelidate/core";
import {email, helpers, required} from "@vuelidate/validators";
import i18n from "@/i18n";
export default {
  name: "ChangeEmailModal",
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Dialog
  },
  data() {
    return {
      newEmail: ''
    }
  },
  validations () {
    return {
      newEmail: {
        required: helpers.withMessage(i18n.global.t('validations.required'), required),
        email: helpers.withMessage(i18n.global.t('validations.email'), email)
      },
    }
  },
  computed: {
    ...mapState(authStore, [
      'userInfo',
      'changeEmailModalState',
    ]),
    modalState: {
      get() {
        return this.changeEmailModalState;
      },
      set() {
        this.closeModal();
      },
    },
  },
  methods: {
    ...mapActions(authStore, [
      'toggleChangeEmailModalState',
      'changeEmailRequest'
    ]),
    closeModal() {
      this.newEmail= '';
      this.v$.$reset();
      this.toggleChangeEmailModalState();
    },
    changeEmail() {
      this.v$.$touch();
      if (this.v$.$validate()) {
        this.changeEmailRequest(this.newEmail);
      }
    }
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="change-email-modal"
          modal
          :header="$t('header.message.noApprovedUser.sendMessageAgain')"
  >
    <div>
      <div class="form-row">
        <span class="form-text">
          {{$t('header.message.noApprovedUser.currentEmail')}}
        </span>
        <span class="form-text">{{userInfo.email}}</span>
      </div>
      <div class="form-row">
        <span class="form-text">
          {{$t('header.message.noApprovedUser.newEmail')}}
        </span>
        <div class="field-wrap">
          <input type="text"
                 v-model="newEmail"
                 placeholder="First name"
                 class="form-control"
                 :class="{'form-control--error': v$.newEmail.$error}"
                 maxlength="60"
          />
          <span v-for="error of v$.newEmail.$errors" :key="error.$uid"
                class="form-control-error"
          >
          {{ error.$message }}
        </span>
        </div>
      </div>
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn"
              @click="closeModal"
      >
        {{$t('common.buttons.cancel')}}
      </button>
      <button class="p-dialog-primary-btn" @click="changeEmail">
        {{$t('header.message.noApprovedUser.changeEmail')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.change-email-modal {
  min-width: 410px;
  width: 410px;

  .p-dialog-content {
    padding: 25px 30px;
  }

  .form-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .form-text {
      min-width: 100px;
      margin-right: 10px;
      text-align: right;
    }
  }

  .form-text {
    font-size: 12px;
  }

  .field-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .form-control {
      min-width: 205px;
      background: #fff;
      display: inline-block;
      font-size: 12px;
      border: 1px solid #cccccc;
      height: 30px;
      color: #4f4f4f;
      padding: 0 30px 0 8px;
      appearance: none;
      outline: none;
      &::placeholder {
        color: #bbbbbb;
      }
      &:focus {
        border-color: #999999;
        color: #000000;
      }
      &__char-count {
        position: absolute;
        left: 175px;
        top: 5px;
        padding-left: 7px;
        border-left: 1px solid #ccc;
        line-height: 20px;
        font-size: 12px;
        color: #4f4f4f;
      }
      &--error {
        border-color: #fe8f18;
      }
    }

    .form-control-error {
      position: absolute;
      top: 35px;
      left: 0;
      display: block;
      font-size: 12px;
      color: #fe8f18;
    }
  }
}
</style>