import { defineStore } from 'pinia';
import axios from "axios";

export const reportStore = defineStore({
    id: 'reportStore',
    state: () => ({
        reportModalState: false,
        reportSentState: false
    }),
    actions: {
        toggleReportModal() {
            this.reportModalState = !this.reportModalState;
        },
        resetReportSentState() {
            this.reportSentState = false;
        },
        sendReportAction(contentUrl, issueDescription) {
            return axios.post(`/censorship/sendrequest?text=${issueDescription}&url=${contentUrl}`)
                .then(() => {
                    this.reportSentState = true;
                })
                .catch(error => console.log(error));
        },

    }
});