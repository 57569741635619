<script>
import BaseIframe from "@/components/BaseIframe.vue";
export default {
  name: "BaseIframeView",
  components: {
    BaseIframe
  },
  computed: {
    frameUrl() {
      const url = this.$route.fullPath;
      let newUrl = this.$route.fullPath;

      if (!url.includes('_f=true')) {
        if (!url.match(/\?./)) {
          newUrl += '?_f=true';
        } else {
          newUrl += '&_f=true';
        }
      }

      return newUrl;
    }
  },
}
</script>

<template>
  <div class="iframe-wrap">
    <Base-iframe
        :src="frameUrl"
    ></Base-iframe>
  </div>
</template>

<style lang="scss" scoped>
.iframe-wrap {
  height: 100vh;
  overflow: hidden;

  iframe {
    height: calc(100vh);
    width: 100%;
    border: none;
    box-sizing: border-box;
  }
}
</style>