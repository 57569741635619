<script>
import {mapActions, mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import Dialog from "primevue/dialog";
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";

export default {
  name: "ActualProposalModal",
  props: {
    actualProposal: {
      type: Object
    }
  },
  components: {
    Dialog,
    VuePdfApp
  },
  data() {
    return {
      modalTabs: {},
      config: {
        sidebar: false,
        secondaryToolbar: false,
        toolbar: false,
        errorWrapper: true,
      }

    }
  },
  computed: {
    ...mapState(proposalStore, [
        'actualProposalModalState',
        'actualProposalContent'
    ]),
    modalState: {
      get() {
        return this.actualProposalModalState;
      },
      set() {
        this.toggleActualProposalModal();
      },
    },
    actualProposalContentType() {
      return this.actualProposal.type;
    }
  },
  methods: {
    ...mapActions(baseLoaderStore, [
      'showBaseLoader',
      'hideBaseLoader'
    ]),
    ...mapActions(proposalStore, [
        'toggleActualProposalModal',
        'getActualTextContent',
        'getActualDocContent'
    ]),
    documentLoaded() {
      this.hideBaseLoader();
    }
  },
  mounted() {
    if (this.actualProposalContentType === "HTML_TEXT") {
      this.getActualTextContent(this.actualProposal.contentId).then(()=> {
        this.documentLoaded();
      });
    }

    this.showBaseLoader();
  },
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="actual-proposal-modal"
          modal
          :header="$t('actualProposal.title')"
  >
<!--    <div class="actual-proposal-controls">-->
<!--      <div class="actual-proposal-control">-->
<!--        See list of source references-->
<!--      </div>-->
<!--    </div>-->
    <div class="actual-proposal-content">
      <template v-if="actualProposalContentType === 'HTML_TEXT'">
        <div v-html="actualProposalContent"
             class="actual-proposal-text"
        ></div>
      </template>
      <template v-else-if="this.actualProposalContentType === 'PDF'">
        <vue-pdf-app style="height: 700px;"
                     :pdf="`/api/attachment/${this.actualProposal.attachments[0].hash}/content`"
                     :config="config"
                     @pages-rendered="documentLoaded"
        ></vue-pdf-app>
      </template>
    </div>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="toggleActualProposalModal()">
        {{$t('common.buttons.close')}}
      </button>
    </template>
  </Dialog>
</template>

<style lang="scss">
.actual-proposal-modal {
  min-width: 950px;
  max-width: 950px;
  min-height: 800px;

  .p-dialog-content {
    height: 700px;
    padding: 0;

    .actual-proposal-controls {
      display: flex;
      align-items: center;
      height: 30px;
      margin-bottom: 15px;
      padding: 0 15px;
      border-radius: 4px;
      box-shadow: 0 0 6px 0 rgba(79, 79, 79, 0.27);
      border: transparent;

      .actual-proposal-control {
        font-size: 14px;
        font-weight: 600;
        color: #4f4f4f;
        background: #fff;
        &--active {
          color: #7835a6;
          background: #fff;
        }
      }
    }

    .actual-proposal-content {
      .actual-proposal-text {
        padding: 15px;
        font-size: 14px;
        line-height: 21px;
        color: #4f4f4f;

        * {
          margin: 0 !important;
          padding: 0 !important;
        }
      }

      .toolbar {
        display: none;
      }
    }

    #viewerContainer {
      position: absolute !important;
      left: 0 !important;

      .textLayer {
        max-width: 820px !important;
      }

      .page {
        width: 90% !important;
        margin-bottom: 10px;

        canvas {
          height: 1125px !important;
          max-width: 820px !important;
        }
      }
    }

    .canvasWrapper {
      height: 100% !important;
      width: 90% !important;
      overflow: visible !important;
    }

  }
}

</style>