<script>
import BaseIframe from "@/components/BaseIframe.vue";
export default {
  name: "ManagerLoungeView",
  components: {
    BaseIframe
  },
  computed: {
    frameUrl() {
      let url = this.$route.fullPath;

      if (!url.includes('_f=true')) {
        if (!url.match(/\?./)) {
          url += '?_f=true';
        } else {
          url += '&_f=true';
        }
      }
      return url;
    }
  }
}
</script>

<template>
  <Base-iframe
      :src="frameUrl"
      :disable-loader="true"
  />
</template>
