// getForumsAction

import { defineStore } from 'pinia';
import axios from 'axios';
export const forumSelectStore = defineStore({
    id: 'forumSelectStore',
    state: () => ({
        forumsL1: [],
        forumsL2: [],
        forumsL3: [],
        forumsL4: [],
    }),
    actions: {
        getForumsAction(level) {
            return axios.get(`/api/follow/forums/tree?level=${level}`, {
                data: {},
            }).then((resp) => {
                const { data } = resp;
                this[`forumsL${level}`] = data;
            });
        },
        registerOrganizationAction(orgData) {
            const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }};
            return axios.post('/registerorganization', orgData, config).then(() => {
                window.location.href = '/organizationwizard';
            });
        }
    }
})