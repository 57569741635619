import {defineStore} from 'pinia';
import {mapActions} from "pinia";
import axios from "axios";
import {baseLoaderStore} from "@/store/baseLoaderStore";

export const contentReviewStore = defineStore({
    id: 'contentReviewStore',
    state: () => ({
        crItemProposersModalState: false,
        crItemProposers: []
    }),
    actions: {
        ...mapActions(baseLoaderStore, [
            'showBaseLoader',
            'hideBaseLoader'
        ]),
        toggleCrItemProposersModalState() {
            this.crItemProposersModalState = !this.crItemProposersModalState;
        },
        showCrProposersModal(proposalIssueNumber) {
            this.showBaseLoader();
            this.getCrItemProposers(proposalIssueNumber);
        },
        getCrItemProposers(issueNumber) {
            this.showBaseLoader();
            return axios.get(`/api/proposals?issueNumbers=${issueNumber}&page=0&size=10&statuses=CURRENT&statuses=ARCHIVED`)
                .then((res) => {
                    let proposalId = res.data.content.length > 0 ? res.data.content[0].id : null;

                    return axios.get(`/api/proposals/${proposalId}`)
                        .then((res) => {
                            this.crItemProposers = res.data.extraAttributes.spokes;

                            this.toggleCrItemProposersModalState();
                            this.hideBaseLoader();
                        });
            });
        },

    }
});