import { defineStore } from 'pinia';

export const successMessageStore = defineStore({
    id: 'successMessageStore',
    state: () => ({
        successMessageState: false,
        displayTime: 2000,
        messageText: ''
    }),
    actions: {
        showSuccessMessage(msgText, showDelay) {
            setTimeout( ()=>{
                this.messageText = msgText;
                this.successMessageState = true;
                setTimeout( ()=> {
                    this.successMessageState = false;
                    this.messageText = '';
                }, this.displayTime);
            }, showDelay || 0);

        },
    }
});