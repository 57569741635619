<script>
import Dialog from "primevue/dialog";
import {mapActions, mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";

export default {
  name: "ActualProposalExamplesModal",
  props: {
    dialogState: {
      type: Boolean
    }
  },
  components: {
    Dialog
  },
  computed: {
    ...mapState(proposalStore, [
      'actualExamplesModalState'
    ]),
    modalState: {
      get() {
        return this.actualExamplesModalState;
      },
      set() {
        this.toggleActualExamplesModal();
      },
    },
  },
  methods: {
    ...mapActions(proposalStore, [
      "toggleActualExamplesModal"
    ]),
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          :header="'Why this change is needed of the most popular manually created proposals'"
          class="actual-examples-modal"
          modal
  >
    <div>
      <ul class="examples-of-actual-proposal-list">
        <li class="example-of-actual-text">Lorem ipsum dolor sit amet, consectetur.</li>
        <li class="example-of-actual-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. In maxime soluta vel. Ab commodi cum error harum optio perferendis sint!</li>
        <li class="example-of-actual-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur excepturi illo ipsam itaque iusto non rerum.</li>
        <li class="example-of-actual-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae consequuntur non voluptates.</li>
        <li class="example-of-actual-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, quisquam.</li>
        <li class="example-of-actual-text">Lorem ipsum dolor.</li>
      </ul>
    </div>
  </Dialog>
</template>

<style lang="scss">
.actual-examples-modal {
  width: 610px;
  .p-dialog-content {
    padding: 15px 15px 18px;
  }
  .examples-of-actual-proposal-list {
    .example-of-actual-text {
      padding: 15px;
      border-radius: 5px;
      box-shadow: 0 2px 9px 0 rgba(79, 79, 79, 0.15);
      margin-bottom: 12px;
    }
  }
}
</style>