<script>
export default {
  name: "BaseNoResultMessage",
  props: {
    text: {
      type: String
    }
  }
}
</script>

<template>
<div class="no-result-message">
  <div class="no-result-message__img"></div>
  <span class="no-result-message__text">{{text}}</span>
</div>
</template>

<style lang="scss" scoped>
.no-result-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  justify-content: center;
  padding: 15px;
  background: #fff;
  &__img {
    width: 150px;
    height: 150px;
    margin-bottom: 15px;
    background: url("~@/assets/img/no-results/no-results-1.svg") no-repeat;
  }
  &__text {
    font-size: 16px;
    font-weight: 600;
    color: #4f4f4f;
  }
}
</style>