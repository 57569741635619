<script>
import {mapState} from "pinia";
import {contentReviewStore} from "@/store/contentReviewStore";
import BaseIframe from "@/components/BaseIframe.vue";
import CrProposersListModal from "@/components/Modals/CrProposersListModal.vue";

export default {
  name: "UserMyDesktopView",
  components: {
    BaseIframe,
    CrProposersListModal
  },
  computed: {
    ...mapState(contentReviewStore,[
        'crItemProposers'
    ]),
    frameUrl() {
      const url = this.$route.fullPath;
      let newUrl = this.$route.fullPath;

      if (!url.includes('_f=true')) {
        if (!url.match(/\?./)) {
          newUrl += '?_f=true';
        } else {
          newUrl += '&_f=true';
        }
      }

      return newUrl;
    },
  }
}
</script>

<template>
  <Base-iframe
      :disable-loader="false"
      :src="frameUrl"
      ref="userMyDesktopView"
  />
  <cr-proposers-list-modal :proposers-list="crItemProposers"/>
</template>