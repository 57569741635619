<script>
import videojs from "video.js";
import {mapActions} from "pinia";
import {argumentStore} from "@/store/argumentStore";
import ProposalArgumentInfo from "@/views/proposal/proposal-argument/ProposalArgumentInfo.vue";
import ProposalArgumentPlayerPlaylist from "@/views/proposal/proposal-argument/ProposalArgumentPlayerPlaylist.vue";
export default {
  name: "ProposalArgumentVideo",
  props: {
    content: {
      type: Object
    }
  },
  components: {
    ProposalArgumentInfo,
    ProposalArgumentPlayerPlaylist
  },
  data() {
    return {
      player: null,
      showEndOverlay: false,
    }
  },
  computed: {
    videoSource() {
      const videoContent = this.content.attachments.filter((item)=> {
        return item.type === 'VIDEO';
      })[0];

      return `/api/video/${videoContent.hash}/stream`;
    },
    videoPreview() {
      const videoPreview = this.content.attachments.filter((item)=> {
        return item.type === 'IMAGE';
      })[0];

      return `/api/video/${videoPreview.hash}/preview`;
    },
    playerOptions() {
      return {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: this.videoSource,
            type: 'video/mp4'
          }
        ],
        buttons: [

        ]
      }
    }
  },
  methods: {
    ...mapActions(argumentStore, [
      'prevArgumentAction',
      'nextArgumentAction'
    ]),
    initPlayer() {
      const self = this;
      this.player = videojs(this.$refs.videoPlayer, this.playerOptions, () => {
        const player = videojs('argVideoPlayer');
        const Button = videojs.getComponent("Button");
        const PrevButton = videojs.extend(Button, {
          constructor: function() {
            Button.apply(this, arguments);
            this.addClass("vjs-icon-previous-item");
            this.controlText("Previous");
          },
          handleClick() {
            self.prevArgumentAction();
          }
        });
        const NextButton = videojs.extend(Button, {
          constructor: function() {
            Button.apply(this, arguments);
            this.addClass("vjs-icon-next-item");
            this.controlText("Next");
          },

          handleClick() {
            self.nextArgumentAction();
          }
        });

        videojs.registerComponent("NextButton", NextButton);
        videojs.registerComponent("PrevButton", PrevButton);

        player.getChild("controlBar").addChild("PrevButton", {}, 1);
        player.getChild("controlBar").addChild("NextButton", {}, 2);

        this.player.log('onPlayerReady', this);

        player.on('ended', function() {
          self.showEndOverlay = true;
        });
      });
    }
  },
  mounted() {
    this.initPlayer();
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>

<template>
  <div class="arg-video-player-wrap">
    <div class="argument-info-wrap">
      <proposal-argument-info/>
    </div>
    <video ref="videoPlayer"
           id="argVideoPlayer"
           class="video-js vjs-default-skin"
           preload="none"
           width="637"
           height="357"
           :poster="videoPreview"
    ></video>
    <proposal-argument-player-playlist v-if="showEndOverlay"></proposal-argument-player-playlist>
  </div>
</template>

<style lang="scss" >
@import "video.js/dist/video-js.css";

.arg-video-player-wrap {
  height: 357px;
  position: relative;
  .argument-info-wrap {
    position: absolute;
    z-index: 1;
    width: 100%;
    padding: 5px;
  }

  .video-js.vjs-paused {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 637px;
      height: 60px;
      background-image: linear-gradient(180deg,rgba(0,0,0,.8),transparent);
      transition: visibility 0.3s, opacity 0.3s linear;
    }
  }

  .video-js {
    .vjs-big-play-button {
      width: 87px !important;
      height: 87px !important;
      margin-left: -44px;
      margin-top: -44px;
      left: 50%;
      top: 50%;
      background: url("~@/assets/img/arguments/video-play-btn-75.svg") no-repeat center;
      background-color: transparent !important;
      border: none;

      &:before {
        display: none;
      }
    }
  }

  .video-js .vjs-menu-button-inline.vjs-slider-active, .video-js .vjs-menu-button-inline:focus, .video-js .vjs-menu-button-inline:hover, .video-js.vjs-no-flex .vjs-menu-button-inline {
    width: 10em;
  }

  .video-js .vjs-controls-disabled .vjs-big-play-button {
    display: none !important;
  }

  .video-js .vjs-control {
    width: 34px;
  }

  .video-js .vjs-menu-button-inline:before {
    width: 1.5em;
  }

  .vjs-menu-button-inline .vjs-menu {
    left: 3em;
  }

  .vjs-paused.vjs-has-started.video-js .vjs-big-play-button, .video-js.vjs-ended .vjs-big-play-button, .video-js.vjs-paused .vjs-big-play-button {
    display: block;
  }

  .video-js .vjs-load-progress div, .vjs-seeking .vjs-big-play-button, .vjs-waiting .vjs-big-play-button {
    display: none !important;
  }

  .video-js .vjs-mouse-display:after, .video-js .vjs-play-progress:after {
    padding: 0 .4em .3em
  }

  .video-js.vjs-ended .vjs-loading-spinner {
    display: none;
  }

  .video-js.vjs-ended .vjs-big-play-button {
    display: block !important;
  }

  .video-js *, .video-js:after, .video-js:before {
    box-sizing: inherit;
    font-size: inherit;
    color: inherit;
    line-height: inherit;
  }

    .video-js.vjs-fullscreen, .video-js.vjs-fullscreen .vjs-tech {
      width: 100% !important;
      height: 100% !important;
    }

    .video-js {
      font-size: 14px;
      overflow: hidden
    }

    .video-js .vjs-control {
      color: inherit;
    }

    .video-js .vjs-menu-button-inline:hover, .video-js.vjs-no-flex .vjs-menu-button-inline {
      width: 8.35em;
    }

    .video-js .vjs-volume-menu-button.vjs-volume-menu-button-horizontal:hover .vjs-menu .vjs-menu-content {
      height: 3em;
      width: 6.35em;
    }

    .video-js .vjs-control:focus:before, .video-js .vjs-control:hover:before {
      text-shadow: 0 0 1em #fff, 0 0 1em #fff, 0 0 1em #fff;
    }

    .video-js .vjs-spacer, .video-js .vjs-time-control {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-flex: 1 1 auto;
      -moz-box-flex: 1 1 auto;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
    }

    .video-js .vjs-time-control {
      -webkit-box-flex: 0 1 auto;
      -moz-box-flex: 0 1 auto;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      width: auto;
      font-size: 10px !important;
      padding-left: 0;
      padding-right: 0;
    }

    .video-js .vjs-time-control.vjs-time-divider {
      width: 14px;
    }

    .video-js .vjs-time-control.vjs-time-divider div {
      width: 100%;
      text-align: center;
    }

    .video-js .vjs-time-control.vjs-current-time {
      margin-left: 7px;
    }

    .video-js .vjs-volume-panel {
      margin-left: 5px;
    }

    .video-js .vjs-time-control .vjs-current-time-display, .video-js .vjs-time-control .vjs-duration-display {
      width: 100%;
    }

    .video-js .vjs-time-control .vjs-current-time-display {
      text-align: right;
    }

    .video-js .vjs-time-control .vjs-duration-display {
      text-align: left;
    }

    .video-js .vjs-remaining-time, .video-js .vjs-volume-level:after, .video-js .vjs-volume-level:before, .video-js.vjs-live .vjs-time-control.vjs-current-time, .video-js.vjs-live .vjs-time-control.vjs-duration, .video-js.vjs-live .vjs-time-control.vjs-time-divider, .video-js.vjs-no-flex .vjs-time-control.vjs-remaining-time {
      display: none;
    }

    .video-js .vjs-play-progress:before, .video-js .vjs-progress-control .vjs-play-progress:before {
      font-size: 9px;
    }

    .video-js .vjs-progress-control:hover .vjs-play-progress:before, .video-js .vjs-progress-control:hover .vjs-progress-control .vjs-play-progress:before {
      font-size: 12px;
      top: -3px !important;
    }

    .video-js.vjs-no-flex .vjs-time-control {
      display: table-cell;
      width: 4em;
    }

    .video-js .vjs-progress-control {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      top: -3px !important;
    }

    .video-js .vjs-progress-control .vjs-load-progress, .video-js .vjs-progress-control .vjs-play-progress, .video-js .vjs-progress-control .vjs-progress-holder {
      height: 100%;
    }

    .video-js .vjs-progress-control .vjs-progress-holder {
      margin: 0 8px;
      background-color: rgba(255, 255, 255, 0.2);
    }

    .video-js .vjs-load-progress {
      background-color: rgba(255, 255, 255, 0.2);
    }

    .video-js .vjs-progress-control:hover {
      height: 6px;
      top: -6px;
    }

    .video-js .vjs-control-bar {
      -webkit-transition: -webkit-transform .1s ease 0s;
      -moz-transition: -moz-transform .1s ease 0s;
      -ms-transition: -ms-transform .1s ease 0s;
      -o-transition: -o-transform .1s ease 0s;
      transition: height 0.15s ease-in !important;

      &.collapse {
        height: 5px !important;
      }
    }

    .video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-control-bar, .video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-control-bar, .video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar, .video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar, .video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar {
      visibility: visible;
      opacity: 1;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translateY(3em);
      -moz-transform: translateY(3em);
      -ms-transform: translateY(3em);
      -o-transform: translateY(3em);
      transform: translateY(3em);
      -webkit-transition: -webkit-transform 1s ease 0s;
      -moz-transition: -moz-transform 1s ease 0s;
      -ms-transition: -ms-transform 1s ease 0s;
      -o-transition: -o-transform 1s ease 0s;
      transition: transform 1s ease 0s;
    }

    .video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-progress-control, .video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-progress-control, .video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-progress-control, .video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control, .video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control {
      height: .25em;
      top: -.25em;
      pointer-events: none;
      -webkit-transition: height 1s, top 1s;
      -moz-transition: height 1s, top 1s;
      -ms-transition: height 1s, top 1s;
      -o-transition: height 1s, top 1s;
      transition: height 1s, top 1s;
    }

    .video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active.vjs-fullscreen .vjs-progress-control, .video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive.vjs-fullscreen .vjs-progress-control, .video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active.vjs-fullscreen .vjs-progress-control, .video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control, .video-js.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control {
      opacity: 0;
      -webkit-transition: opacity 1s ease 1s;
      -moz-transition: opacity 1s ease 1s;
      -ms-transition: opacity 1s ease 1s;
      -o-transition: opacity 1s ease 1s;
      transition: opacity 1s ease 1s;
    }

    .video-js.vjs-live .vjs-live-control {
      margin-left: 1em;
    }


    .video-js .vjs-big-play-button .vjs-icon-placeholder {
      display: none;
    }

    .video-js .vjs-menu-button-popup .vjs-menu {
      left: -3em;
    }

    .video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
      background-color: transparent;
      width: 12em;
      left: -1.5em;
      padding-bottom: .5em;
    }

    .video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item, .video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-title {
      background-color: #151b17;
      margin: .3em 0;
      padding: .5em;
      border-radius: .3em;
    }

    .video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected {
      background-color: #fff;
    }

    .video-js:hover .vjs-big-play-button, .video-js .vjs-big-play-button:focus, .video-js .vjs-big-play-button:active {
      background-color: #000000;
    }

    .video-js .vjs-loading-spinner {
      border-color: #000000;
    }

    .video-js .vjs-control-bar2 {
      background-color: transparent;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    }

    .video-js .vjs-control-bar {
      height: 29px;
      background-color: transparent !important;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
      color: #ffffff;
      font-size: 14px;
    }

    .video-js .vjs-play-progress, .video-js .vjs-volume-level {
      background-color: #fff;
    }

    .vjs-button > .vjs-icon-placeholder:before,
    .video-js .vjs-time-control {
      font-size: 20px;
      line-height: 29px;
    }

    .video-js .vjs-volume-bar {
      margin: 12px .45em;
    }

    .video-js .vjs-icon-previous-item,
    .video-js .vjs-icon-next-item {
      width: 20px !important;
      font-size: 20px;
    }



}
</style>