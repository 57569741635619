<script>
import {mapActions, mapState} from "pinia";
import {authStore} from "@/store/authStore";
import Dialog from "primevue/dialog";
export default {
  name: "RegisterPartyInfoModal",
  components: {
    Dialog
  },
  computed: {
    ...mapState(authStore, [
      'registerPartyInfoState'
    ]),
    modalState: {
      get() {
        return this.registerPartyInfoState;
      },
      set() {
        this.closeModal();
      },
    },
  },
  methods: {
    ...mapActions(authStore, [
      'toggleRegisterPartyInfoModal'
    ]),
    closeModal() {
      this.toggleRegisterPartyInfoModal();
    }
  }
}
</script>

<template>
  <Dialog v-model:visible="modalState"
          class="register-party-info-modal"
          modal
          :header="$t('registerPartyInfo.title')"
  >
    <div class="info-text">
      {{$t('registerPartyInfo.info.p1')}}
      <a href="https://www.val.se/for-partier/registrera-partibeteckning/registrera-partibeteckning.html" target="_blank" class="info-text--link">
        {{$t('registerPartyInfo.info.p2')}}
      </a>.
      {{$t('registerPartyInfo.info.p3')}}
    </div>
    <template #footer>
      <button class="p-dialog-primary-btn"
              @click="closeModal"
      >
        {{$t('common.buttons.ok')}}
      </button>
    </template>
  </Dialog>

</template>

<style lang="scss">
.register-party-info-modal {
  max-width: 680px;

  .p-dialog-content {
    padding: 23px 32px;
  }

  .info-text {
    line-height: 18px;
    &--link {
      font-weight: 600;
      color: #7835a6;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

</style>