import { defineStore } from 'pinia';

export const baseLoaderStore = defineStore({
    id: 'baseLoaderStore',
    state: () => ({
        loaderState: false,
    }),
    actions: {
        showBaseLoader() {
            this.loaderState = true;
        },
        hideBaseLoader() {
            this.loaderState = false;
        }
    }
})