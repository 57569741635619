<script>
import {mapState} from "pinia";
import {successMessageStore} from "@/store/successMessageStore";

export default {
  name: "BaseSuccessMessage",
  computed: {
    ...mapState(successMessageStore, [
        'messageText'
    ]),
  }
}
</script>

<template>
  <div class="success-message-modal-overlay">
    <div class="success-message-modal">
      <div class="success-message-modal__icon"></div>
      <span class="success-message-modal__text">{{messageText}}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.success-message-modal-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1102;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);

  .success-message-modal {
    display: flex;
    align-items: center;
    min-width: 375px;
    height: 80px;
    padding: 0 20px;
    border-radius: 4px 4px 4px 4px;
    background: #7835a6;

    &__icon {
      width: 53px;
      height: 56px;
      margin-right: 20px;
      background: url("~@/assets/img/icon_edit_profile.png") center no-repeat;
    }
    &__text {
      padding: 0 10px;
      color: #ffffff;
      font-size: 20px;
      font-weight: 600;
    }
  }
}
</style>