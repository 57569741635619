<script>
import BaseHeader from "@/components/BaseHeader.vue";
import BaseIframe from "@/components/BaseIframe.vue";
export default {
  name: "AboutView",
  components: {
    BaseHeader,
    BaseIframe
  },
}
</script>

<template>
  <base-header/>
  <Base-iframe
      :src="'/about?_f=true'"
  />
</template>