<script>
import {mapActions, mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";
import {authStore} from "@/store/authStore";
import {bankIdStore} from "@/store/bankIdStore";


export default {
  name: "ProposalCreateSearch",
  data() {
    return {
      searchTimeout: null,
      searchedProposalTitle: ''
    }
  },
  computed: {
    ...mapState(authStore, [
        'activeUserInfo'
    ]),
    ...mapState(proposalStore, [
      'registerPartyInfoState',
      'searchingState'
    ]),
    createPropBtnState() {
      return this.searchedProposalTitle.length >= 5;
    }
  },
  methods: {
    ...mapActions(proposalStore, [
      'searchExistingProposals',
      'openCreatePropFormView',
      'toggleEditProposalState',
      'setSavePropValue',
      'setSearchPropString',
      'createProposalAction'
    ]),
    ...mapActions(bankIdStore, [
        'showBankIdModal',
        'checkBankIdAuthSession'
    ]),
    runProposalsSearch() {
      if(this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }

      this.searchTimeout = setTimeout(()=> {
        this.setSearchPropString(this.searchedProposalTitle);
        this.searchExistingProposals();
      }, 500)
    },

    openEditPropView() {
      if (this.createPropBtnState) {
        this.checkBankIdAuthSession(()=> {
          this.setSavePropValue('proposerId', this.activeUserInfo.id);
          this.setSavePropValue('title', this.searchedProposalTitle);
          this.setSavePropValue('importanceType', 'MATTER_OF_THE_HEART');
          this.createProposalAction().then(()=> {
            this.openCreatePropFormView();
          });
        })
      }
    }
  },
}
</script>

<template>
  <div class="proposal-create-section">
    <div class="d-flex">
       <span class="proposal-create-section__title">
        2. {{$t('editProposal.enterProposal')}}
      </span>
      <div v-if="searchingState" class="proposal-create-section-loader"></div>
    </div>
    <div class="search-entered-prop-wrap">
      <textarea v-model="searchedProposalTitle"
                @keyup="runProposalsSearch"
                class="search-entered-prop-field"
                :placeholder="$t('editProposal.writeYourProposalHere')"
                cols="20"
                rows="2"
      ></textarea>
    </div>
    <button type="button"
            :class="{'create-proposal-btn--disabled': !createPropBtnState}"
            class="create-proposal-btn"
            @click="openEditPropView()"
    >
      {{$t('editProposal.createProposal')}}
    </button>
  </div>
</template>

<style lang="scss">
.search-entered-prop-wrap {
  margin-bottom: 15px;
}

.proposal-create-section-loader {
  display: flex;
  margin-left: auto;
  width: 25px;
  height: 25px;
  border: 5px solid #7835a6;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.search-entered-prop-field {
  border: 1px solid #ccc;
  padding: 10px 12px;
  resize: none;
  width: 100%;
  font-size: 14px;
  height: 60px;
  overflow-y: auto;
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 400;
  outline: none;
}

.create-proposal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  margin-left: auto;
  padding: 0 13px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', Arial, sans-serif;
  border-radius: 2px;
  color: #FFFFFF;
  background: #7835a6;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #672992;
  }
  &--disabled {
    background: #909090;
    cursor: default;
    color: #ffffff;
    &:hover {
      background-color: #909090;
    }
  }
}
</style>