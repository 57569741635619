<script>
export default {
  name: "ProposalGeneralSubjectsList",
  props: {
    subjectsList: {
      type: Array
    }
  },
  methods: {
    openSubject(item) {
      window.location.href = `/search/result?q=${item}&type=ALL`;
    }
  }
}
</script>

<template>
<div class="subjects-list">
  <div v-for="item in subjectsList" class="subject-item"
       :key="item"
       @click="openSubject(item)"
  >
    {{item}}
  </div>
</div>
</template>

<style lang="scss" scoped>
.subjects-list {
  padding: 10px;
  margin-top: 5px;

  .subject-item {
    height: 24px;
    font-size: 12px;
    line-height: 23px;
    display: inline-block;
    border-radius: 2px;
    background: #f2f2f2;
    margin: 0 10px 5px 0;
    color: #4f4f4f;
    padding: 0 8px;
    letter-spacing: -.01px;
    cursor: pointer;
  }
}
</style>