<script>
import i18n from '@/i18n';
import Menubar from "primevue/menubar";
import Dialog from "primevue/dialog";

export default {
  name: "BaseSidebar",
  components: {
    Menubar,
    Dialog
  },
  data() {
    return {
      gitCommitHash: process.env.VUE_APP_GIT_HASH,
      reportBugModalState: false,
      versionModalState: false,
      menuItems: [
        {
          label: i18n.global.t('sidebar.sidebarMenu.about'),
          command: () => {
            this.openAbout();
          }
        },
        {
          label: i18n.global.t('sidebar.sidebarMenu.privacyAndTerms'),
          items: [
            {
              label: i18n.global.t('sidebar.sidebarMenu.dropdown.privacyPolicy'),
              command: () => {
                this.openPrivacyPolicy();
              }
            },
            {
              label: i18n.global.t('sidebar.sidebarMenu.dropdown.userTerms'),
              command: () => {
                this.openUserTerms();
              }
            },
            {
              label: i18n.global.t('sidebar.sidebarMenu.dropdown.cookiePolicy'),
              command: () => {
                this.openCookiePolicy();
              }
            },
          ]
        },
        {
          label: i18n.global.t('sidebar.sidebarMenu.version'),
          command: () => {
            this.toggleVersionModalStateModal();
          }
        }
      ]
    }
  },
  methods: {
    toggleReportBugModal() {
      this.reportBugModalState = !this.reportBugModalState;
    },
    toggleVersionModalStateModal() {
      this.versionModalState = !this.versionModalState;
    },
    openAbout() {
      this.$router.push({ name: 'About' });
    },
    openPrivacyPolicy() {
      window.open("/privacypolicy", '_blank');
    },
    openUserTerms() {
      window.open("/userterms", '_blank');
    },
    openCookiePolicy() {
      window.open("/cookiepolicy", '_blank')
    },
  }
}
</script>

<template>
<div class="proposal-sidebar">
  <div class="proposal-sidebar__content">
    <div class="heading">
      <h3>{{$t('sidebar.sponsored')}}</h3>
      <a href="/createad" class="more">
        {{$t('sidebar.createAd')}}
      </a>
    </div>
    <div class="heading">
      <RouterLink to="/becomeManager" class="more bm-link">
        {{$t('sidebar.becomeManager')}}
      </RouterLink>
    </div>
    <div class="report-bug-block" @click="toggleReportBugModal">
      <div class="report-bug-block__text">
        {{$t('sidebar.reportBugText')}}
      </div>
    </div>
  </div>
  <div class="proposal-sidebar__footer">
    <Menubar :model="menuItems" class="sidebar-menu" />

    <span class="sidebar-copyright">
        &copy; PeoplePolitics 2019
    </span>
  </div>

  <Dialog v-model:visible="reportBugModalState"
          modal
          class="report-bug-modal"
          :header="$t('sidebar.reportBugModal.title')"
  >
    <p class="report-bug-modal__text">
      {{$t('sidebar.reportBugModal.text.p1')}}
      <a href="mailto:bugs@peoplepolitics.com">bugs@peoplepolitics.com</a>
      {{$t('sidebar.reportBugModal.text.p2')}}
    </p>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="toggleReportBugModal">
        {{$t('common.buttons.close')}}
      </button>
    </template>
  </Dialog>

  <Dialog v-model:visible="versionModalState"
          modal
          class="version-modal"
          :header="$t('sidebar.sidebarMenu.version')"
  >
    <p class="version-modal__text">
      {{$t('sidebar.versionNumber')}} {{ gitCommitHash }}
    </p>
    <template #footer>
      <button type="button" class="p-dialog-close-btn" @click="toggleVersionModalStateModal">
        {{$t('common.buttons.close')}}
      </button>
    </template>
  </Dialog>
</div>
</template>

<style lang="scss">
.proposal-sidebar {
  width: 342px;
  min-width: 342px;

  &__content {
    margin-bottom: 15px;
    padding-bottom: 15px;
    background: #fff;
  }
  &__footer {
    .sidebar-copyright {
      display: flex;
      justify-content: center;
      font-size: 12px;
      color: #bbb;
    }
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 21px 23px;
    height: 61px;
    box-sizing: border-box;
    position: relative;

    &:after {
      position: absolute;
      bottom: -1px;
      left: 14px;
      display: block;
      content: '';
      width: 306px;
      height: 1px;
      background-color: #ECECEC;
      margin: 0 auto;
    }

    h3 {
      font-size: 14px;
      float: left;
      margin: 0;
      font-weight: 600;
      color: #4f4f4f;
    }

    .more {
      float: right;
      font-size: 14px;
      color: #7835a6;
      font-style: normal;
      font-weight: 600;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      &.bm-link {
        margin-left: auto;
      }
    }
  }

  .report-bug-block {
    margin: 12px auto;
    display: block;
    width: 300px;
    height: 100px;
    background: url("~@/assets/img/proposal/bug-report-std-image.svg"), no-repeat, center;
    cursor: pointer;

    &__text {
      float: right;
      margin: 26px 16px;
      text-align: left;
      display: block;
      color: #fff;
      font-weight: 600;
      font-size: 18px;
      line-height: normal;
      width: 134px;
    }
  }

  .sidebar-menu {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0;
    background: transparent;
    border: none;

    .p-menubar-root-list {
      gap: initial;

      > .p-menubar-item-link {
        padding: 2px 10px !important;
      }
    }

    .p-menubar-item {
      display: inline-block;
      border-left: 1px solid #7835a6;

     &:first-child {
       border-left-color: transparent;
     }

      .p-menubar-item-link {
        padding: 2px 10px !important;
        font-size: 14px;
        font-weight: 600;
        color: #7835a6;
      }
    }

    .p-menubar-item-content {
      background: transparent !important;
      &:hover {
        color: #7835a6;
        background: transparent !important;
      }

      .p-icon {
        color: #7835a6 !important;
      }
    }



    .p-menubar-submenu {
      min-width: 130px;
      padding: 5px 0;
      border: none;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 2px 9px 0 rgba(79, 79, 79, 0.15);

      .p-menubar-item {
        border: none;
      }

      .p-menubar-item-link {
        padding: 7px 10px !important;
      }
    }
  }
}

.report-bug-modal {
  max-width: 500px;
  border: none !important;

  &__text {
    padding: 23px 32px;
    font: 12px/18px 'Open Sans',Arial,sans-serif;

    a {
      color: #7835a6
    }
  }

  .p-dialog-content {
    padding: 0;
  }
}

.version-modal {
  min-width: 500px;
  max-width: 500px;
  border: none !important;

  &__text {
    padding: 23px 32px;
    font: 12px/18px 'Open Sans',Arial,sans-serif;
  }

  .p-dialog-content {
    padding: 0;
  }
}
</style>