<script>
import BaseAvatar from "@/components/BaseAvatar/BaseAvatar.vue";
import {mapState} from "pinia";
import {authStore} from "@/store/authStore";
export default {
  name: "ProposalCreateGeneral",
  components: {
    BaseAvatar
  },
  computed: {
    ...mapState(authStore, ['activeUserInfo']),
    proposalOwnerName() {
      return `${this.activeUserInfo.firstName} ${this.activeUserInfo.lastName}`;
    }
  }
}
</script>

<template>
  <div class="proposal-create-section">
    <span class="proposal-create-section__title">
      1. {{$t('editProposal.proposer')}}
    </span>
    <div class="new-prop-info">
      <div class="new-prop-info-block">
        <span class="new-prop-info-label">{{$t('editProposal.proposer')}}:</span>
        <div v-if="activeUserInfo"
             class="proposal-owner"
        >
          <base-avatar :size="'24'"
                       :img-src="'/api/image/avatar'"
                       class="proposal-owner__avatar"
          />
          <span class="proposal-owner__name">
          {{ proposalOwnerName }}
        </span>
        </div>
      </div>
      <div class="new-prop-info-block">
         <span class="new-prop-info-label">
           {{$t('editProposal.forum')}}
         </span>
        <a href="/forum/1" target="_blank" class="proposal-forum-link">Riksdagen</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.new-prop-info {
  display: flex;
  justify-content: space-between;
  .new-prop-info-block {
    display: flex;
    flex-direction: column;
    min-width: 110px;
  }
  .new-prop-info-label {
    margin-bottom: 15px;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    color: #4f4f4f;
  }
}
.proposal-owner {
  display: flex;
  align-items: center;
  &__avatar {
    margin-right: 5px;
  }
  &__name {
    text-decoration: none;
  }
}

.proposal-forum-link {
  padding-top: 5px;
  text-decoration: none;
  color: #7835a6;
}
</style>