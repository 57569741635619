<script>
import BaseIframe from "@/components/BaseIframe.vue";
export default {
  name: "UserTermsView",
  components: {
    BaseIframe
  },
}
</script>

<template>
  <Base-iframe
      :src="'/userterms?_f=true'"
      :use-sandbox="false"
  />
</template>

