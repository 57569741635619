<script>
import {mapState} from "pinia";
import {proposalStore} from "@/store/proposalStore";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseSidebar from "@/components/BaseSidebar.vue";
import ProposalCreateGeneral from "@/views/proposal/proposal-create/ProposalCreateGeneral.vue";
import ProposalCreateSearch from "@/views/proposal/proposal-create/ProposalCreateSearch.vue";
import ProposalCreateSearchResult from "@/views/proposal/proposal-create/ProposalCreateSearchResult.vue";
import EditProposalView from "@/views/proposal/proposal-edit/EditProposalView.vue";
import ProposalActionBar from "@/views/proposal/proposal-action-bar/ProposalActionBar.vue";
export default {
  name: "ProposalCreateView",
  components: {
    BaseHeader,
    BaseSidebar,
    ProposalCreateGeneral,
    ProposalCreateSearch,
    ProposalCreateSearchResult,
    EditProposalView,
    ProposalActionBar,
  },
  computed: {
    ...mapState(proposalStore, [
      'createPropFormViewState',
      'searchPropString',
      'searchingState',
    ]),
  },
  methods: {
    saveProposal(actionType) {
      this.$refs.editProposalView.saveProposal(actionType);
    }
  }
}
</script>

<template>
  <base-header/>
  <div class="content-section">
    <template v-if="!createPropFormViewState">
      <div class="create-proposal-wrap">
        <div class="create-proposal-main">
          <proposal-create-general/>
          <proposal-create-search/>
          <proposal-create-search-result
              v-if="searchPropString.length > 0 && !searchingState"
          />
        </div>
        <div class="create-proposal-sidebar">
          <base-sidebar/>
        </div>
      </div>
    </template>
    <template v-else>
      <edit-proposal-view ref="editProposalView"/>
      <proposal-action-bar :active-user-type="activeUserType"
                           @on-save-proposal="saveProposal"
      />

    </template>
  </div>
</template>

<style lang="scss">
.content-section {
  display: flex;
  justify-content: center;
  min-height: 100%;
  padding-top: 80px;
  background: #E9EAED;
}

.create-proposal-wrap {
  display: flex;
  .create-proposal-main {
    max-width: 600px;
    margin-right: 25px;
    .proposal-create-section {
      overflow: visible;
      display: inline-block;
      width: 600px;
      margin-bottom: 12px;
      padding: 20px 20px 23px;
      background-color: #fff;
      box-shadow: 0 2px 4px 0 rgba(79, 79, 79, .15);
      &__title {
        display: inline-block;
        margin-bottom: 23px;
        font-size: 14px;
        font-weight: 600;
        color: #4f4f4f;
      }
    }
  }
  .create-proposal-sidebar {
    max-width: 334px;
  }
}
</style>