<script>
import {mapState} from "pinia";
import {opinionStore} from "@/store/opinionStore";
import BaseAvatar from "@/components/BaseAvatar/BaseAvatar.vue";
import {formatZonedDateTime} from "@/baseUtils";

export default {
  name: "ProposalOpinionSection",
  components: {
    BaseAvatar
  },
  computed: {
    ...mapState(opinionStore, [
      'currentOpinion'
    ]),
    opinionLikeText() {
      const opinionLike = this.currentOpinion.opinionLike;
      let text = "";

      if (opinionLike === 'VERY_GOOD') {
        text = "Very good proposal";
      } else if (opinionLike === 'PRETTY_GOOD') {
        text = "Pretty good proposal";
      } else if (opinionLike === 'NO_OPINION') {
        text = "No opinion";
      } else if (opinionLike === 'PRETTY_BAD') {
        text = "Pretty bad proposal";
      } else if (opinionLike === 'VERY_BAD') {
        text = "Very bad proposal";
      }

      return text;
    },
    opinionLikeIconClass() {
      const opinionLike = this.currentOpinion.opinionLike;
      let iconClass = "";

      if (opinionLike === 'VERY_GOOD') {
        iconClass = "very-good";
      } else if (opinionLike === 'PRETTY_GOOD') {
        iconClass = "pretty-good";
      } else if (opinionLike === 'NO_OPINION') {
        iconClass = "no-opinion";
      } else if (opinionLike === 'PRETTY_BAD') {
        iconClass = "pretty-bad";
      } else if (opinionLike === 'VERY_BAD') {
        iconClass = "very-bad";
      }

      return iconClass;
    },
    opinionCreateDate() {
      return formatZonedDateTime(this.currentOpinion.createdDateTime);
    },
    opinionPrivacyIcon() {
      return 'public';
    },
    opinionPrivacyCaption() {
      return 'Public';
    }
  },
  methods: {
    closeOpinionSection() {
      this.$router.push({ name: 'ProposalView', params: { id: this.currentOpinion.proposalIds[0] }});
    }
  }
}
</script>

<template>
<div class="opinion-section">
  <base-avatar :size="'XL'"
               class="opinion-owner-avatar"
               :img-hash="currentOpinion.creator.imgHash"
  />
  <div class="opinion-info">
    <div class="opinion-info__general-info-row">
      <span class="opinion-info-label">Opinion from:</span>
      <router-link :to="{name: 'UserView', params: {id: currentOpinion.creator.id}}"
                   target="_blank"
                   class="opinion-owner-name"
      >{{currentOpinion.creator.name}}</router-link>
      <span class="opinion-info-label">-</span>
      <div class="opinion-like">
        <div class="opinion-like__icon" :class="opinionLikeIconClass"></div>
        <span class="opinion-like__text">{{opinionLikeText}}</span>
      </div>
    </div>
    <div class="opinion-info__addition-info-row">
      <span class="opinion-create-date">{{opinionCreateDate}}</span>
      <div class="opinion-logbook-icon"></div>
      <div class="opinion-privacy">
        <div class="opinion-privacy__icon" :class="opinionPrivacyIcon"></div>
        <span class="opinion-privacy__caption">{{ opinionPrivacyCaption }}</span>
      </div>
      <span class="opinion-info-label">-</span>
      <span class="open-opinion-args-control">
        See arguments (0)
      </span>
    </div>
  </div>
  <font-awesome-icon :icon="['fas', 'times']"
                     class="close-opinion-control"
                     @click="closeOpinionSection"
  />
</div>
</template>

<style lang="scss" scoped>
.opinion-section {
  display: flex;
  position: relative;
  height: 85px;
  padding: 10px 15px;
  margin-bottom: 10px;
  background: #fff;

  .opinion-owner-avatar {
    margin-right: 10px;
  }

  .opinion-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__general-info-row {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 600;
    }

    &__addition-info-row {
      display: flex;
      align-items: center;
      font-size: 12px;
    }

    .opinion-info-label {
      margin-right: 5px;
      color: #3c3c3c;
    }

    .opinion-owner-name {
      margin-right: 5px;
    }

    .opinion-like {
      display: flex;
      align-items: center;
      margin-right: 5px;
      &__icon {
        width: 27px;
        height: 27px;
        margin-right: 5px;

        &.very-good {
          background: url("~@/assets/img/opinion/very_good.svg") no-repeat;
        }
        &.pretty-good {
          background: url("~@/assets/img/opinion/pretty_good.svg") no-repeat;
        }
        &.no-opinion {
          background: url("~@/assets/img/opinion/no_opinion.svg") no-repeat;
        }
        &.pretty-bad {
          background: url("~@/assets/img/opinion/pretty_bad.svg") no-repeat;
        }
        &.very-bad {
          background: url("~@/assets/img/opinion/very_bad.svg") no-repeat;
        }
      }
      &__text {
        color: #3c3c3c;
      }
    }

    .opinion-create-date {
      margin-right: 5px;
      color: #7835a6;
      cursor: pointer;
    }

    .opinion-logbook-icon {
      width: 15px;
      height: 18px;
      margin-right: 15px;
      background: url("~@/assets/img/opinion/logbook.svg") center no-repeat;
      cursor: pointer;
    }

    .opinion-privacy {
      display: flex;
      align-item: center;
      margin-right: 5px;
      &__icon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: .7;

        &.public {
          background: url("~@/assets/img/opinion/PUBLIC.svg") no-repeat;
        }
        &.private {
          background: url("~@/assets/img/opinion/PRIVATE.svg") no-repeat;
        }
      }
      &__caption {
        font-size: 12px;
        letter-spacing: -.01px;
        color: #7b7b7b;
      }
    }

    .open-opinion-args-control {
      color: #7835a6;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .close-opinion-control {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    color: #7b7b7b;
    cursor: pointer;
  }
}
</style>