const AVATAR_SIZE = {
  18: '18',
  SM: '21',
  24: '24',
  26: '26',
  28: '28',
  32: '32',
  30: '30',
  M: '44',
  XL: '64',
};

export default AVATAR_SIZE;
