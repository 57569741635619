<script>
export default {
  name: "BaseMoveToControl",
  props: {
    positionTop: {
      type: Number
    }
  },
  data() {
    return {
      showControlState: true,
      offset: 500,
      duration: 550
    }
  },
  methods: {
    updateProgress() {
      const progressPath = document.querySelector('.move-to-control path');
      const pathLength = progressPath.getTotalLength();
      const scroll = window.scrollY;
      const height = document.documentElement.scrollHeight;

      progressPath.style.strokeDashoffset = pathLength - (scroll * pathLength / height);
    },

    scrollTo(elem) {
      const el = document.getElementById(elem);

      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    }
  },
  mounted() {
    this.updateProgress()
    window.addEventListener('scroll', () => {
      this.updateProgress()
      this.showControlState = window.scrollY < 3000;
    });
  }
}
</script>

<template>
  <div class="move-to-control" :class="{'active-progress': showControlState}"
       :style="{ 'top': positionTop + 'px' }"
       @click="scrollTo('welcomeFormSection')"
       v-tooltip.left="{ value: $t('becomeManager.applicationFormAtTheBottom'), class: 'move-to-control__tooltip' }"
  >
    <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
  </div>
</template>

<style lang="scss">
.move-to-control {
  position: fixed;
  top: 90px;
  right: 15px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;

  &.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  &::before {
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: '\f063';
    text-align: center;
    line-height: 46px;
    font-size: 18px;
    opacity: 0;
    background-image: linear-gradient(298deg, #672992, #a163cd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 2;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }

  &::after {
    position: absolute;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f063';
    text-align: center;
    line-height: 46px;
    font-size: 18px;
    color: #4F4F4F;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }

  &:hover {
    &::before {
      opacity: 1;
      font-size: 24px;
    }
    &::after {
      opacity: 0;
    }
  }

  svg {
    &.progress-circle path {
      stroke: #4F4F4F;
      stroke-width: 4;
      box-sizing: border-box;
      -webkit-transition: all 200ms linear;
      transition: all 200ms linear;
    }
    path {
      fill: none;
    }
  }

  &__tooltip {
    min-width: 215px;

    .p-tooltip-arrow {
      border-left-color: #4f4f4f !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
    }
  }

  .lightScrollIcon::after {
    color: #ecedf3 !important;
  }
}
</style>