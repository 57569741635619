<script>
import { mapState, mapActions } from 'pinia'
import { baseLoaderStore} from "@/store/baseLoaderStore";
import BaseIframe from "@/components/BaseIframe.vue";
export default {
  name: "NewsFeedView",
  components: {
    BaseIframe
  },
  computed: {
    ...mapState(baseLoaderStore, ['loaderState']),
  },
  methods: {
    ...mapActions(baseLoaderStore, ['hideBaseLoader']),
    setViewport() {
      const viewportWidth = 1024;

      if (screen.width < viewportWidth) {
        const mvp = document.getElementById('metaviewport');
        mvp.setAttribute('content','user-scalable=no,width=' + viewportWidth);
      }
    }
  },
  mounted(){
    this.hideBaseLoader();
    this.setViewport();
  },
}
</script>

<template>
  <Base-iframe
      :src="'/index?_f=true'"
  ></Base-iframe>
</template>