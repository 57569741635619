<script>
export default {
  name: "ProposalNotAvailable"
}
</script>

<template>
  <div class="proposal-not-available">
    <div class="proposal-not-available__bg">
      <div class="proposal-not-available__text-wrap">
        <span class="proposal-not-available__text">
          {{$t('proposalNotAvailable.sorry')}}
        </span>
        {{$t('proposalNotAvailable.thisPageNotAvailable')}}
      </div>
    </div>
    <div class="proposal-not-available__desc">
      {{$t('proposalNotAvailable.descText')}}
    </div>
  </div>

</template>

<style lang="scss" scoped>
.proposal-not-available {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #43235A;

  &__bg {
    display: block;
    width: 764px;
    height: 356px;
    background: url("~@/assets/img/proposal/sorry.png"), no-repeat, center;
    margin-top: 155px;
    color: #fff;
    font-size: 21px;
    line-height: 32px;
    text-transform: uppercase;
    text-align: left;
    font-weight: 400;
  }

  &__text-wrap {
    padding: 140px 0 0 200px;
  }

  &__text {
    font-size: 52px;
    font-weight: 700;
    line-height: 52px;
    padding-right: 10px
  }
  &__desc {
    max-width: 360px;
    padding-top: 175px;
    text-align: center;
    line-height: 18px;
    color: #fff;
  }
}
</style>